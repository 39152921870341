import axios from "../axios-api";
import { SURVEY_RECIPIENT_TYPES } from "Common";

export const getGroups = (token, clientId, recipientType) => {
  const fields = ["id", "name", "category", "type"];
  const requestUrl = `/mailing/group`;

  const filter = [
    {
      property: "client.id",
      value: clientId,
      operator: "eq",
    },
    {
      property: "groupUser.id",
      value: "",
      operator: "nNull",
    },
  ];

  if (recipientType) {
    filter.push({
      property: "category",
      value:
        SURVEY_RECIPIENT_TYPES.indexOf(recipientType) > -1
          ? "CONTACT"
          : recipientType,
      operator: "eq",
    });
  }

  const sort = [
    {
      property: "name",
      dir: "asc",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
      filter: JSON.stringify(filter),
      nolimit: 1,
      sort: JSON.stringify(sort),
    },
  });
};

export const getClientEvents = (token, clientId, data) => {
  const fields = [
    "id",
    "nameFr",
    "nameEn",
    "nameNl",
    "languages",
    "contactFr",
    "contactEn",
    "contactNl",
    "emailContactFr",
    "emailContactEn",
    "emailContactNl",
    "languages",
    "emails_types",
    "eventDates",
  ];

  const filter = [
    {
      property: "client",
      value: clientId,
      operator: "eq",
    },
  ];

  if (data.eventId) {
    filter.push({
      property: "id",
      value: data.eventId,
      operator: "eq",
    });
  } else if (data.searchWord) {
    filter.push({
      property: "nameFr",
      value: data.searchWord,
      operator: "like",
    });
  } else if (data.language) {
    filter.push({
      property: "languages",
      value: data.language,
      operator: "eq",
    });
  }

  const sort = [
    {
      property: "eventDate",
      dir: "desc",
    },
  ];

  const requestUrl = `/event/event`;
  return axios.get(requestUrl, {
    params: {
      fields: fields.join(","),
      access_token: token,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      nolimit: 1,
    },
  });
};

export const getEventCycles = (token, clientId, cycleId, language) => {
  const fields = [
    "id",
    "nameFr",
    "nameEn",
    "nameNl",
    "languages",
    "contactFr",
    "contactEn",
    "contactNl",
    "emailContactFr",
    "emailContactEn",
    "emailContactNl",
    "languages",
    "emails_types",
  ];

  const filter = [
    {
      property: "client",
      value: clientId,
      operator: "eq",
    },
  ];

  if (cycleId) {
    filter.push({
      property: "id",
      value: cycleId,
      operator: "eq",
    });
  } else if (language) {
    filter.push({
      property: "languages",
      value: language,
      operator: "eq",
    });
  }

  const sort = [
    {
      property: "updatedAt",
      dir: "desc",
    },
  ];

  const requestUrl = `/event/cycle`;
  return axios.get(requestUrl, {
    params: {
      fields: fields.join(","),
      access_token: token,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
    },
  });
};

export const getEventFormat = (token, organizationId) => {
  const filter = [
    {
      property: "organization",
      value: organizationId,
      operator: "eq",
    },
  ];

  const sort = [
    {
      property: "updatedAt",
      dir: "desc",
    },
  ];

  const requestUrl = `/event/classification`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
    },
  });
};

export const getClientPartnerEvents = (token, clientId) => {
  const requestUrl = `/event/event/get-events-partner-organization`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      partnerOrganizationId: clientId,
    },
  });
};

export const getEventActivities = (token, idEvent) => {
  const requestUrl = `/event/activity`;

  const filter = [
    {
      property: "event.id",
      value: idEvent,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
    },
  });
};

export const getEventSlot = (
  token,
  { event, searchWord, paginationPage, pageSize },
) => {
  const requestUrl = `/event/slot`;
  const fields = ["*"];
  const filter = [
    {
      property: "event.id",
      value: event,
      operator: "eq",
    },
  ];

  if (pageSize && searchWord) {
    fields.push("speakerName");
    filter.push({
      property: "nameFr",
      value: searchWord,
      operator: "like",
    });
  }

  const params = {
    access_token: token,
    fields: fields.join(","),
    filter: JSON.stringify(filter),
  };

  if (pageSize && paginationPage) {
    params.start = (paginationPage - 1) * pageSize;
    params.limit = pageSize;
    fields.push("speakerName");
  } else {
    params.nolimit = pageSize;
  }

  return axios.get(requestUrl, {
    params,
  });
};
export const getEventFormation = (
  token,
  period,
  searchWord,
  languages,
  tags,
  { pageSize, paginationPage, formationPages },
) => {
  const fields = [
    "id",
    "type",
    "nameFr",
    "nameNl",
    "nameEn",
    "placeFr",
    "placeNl",
    "placeEn",
    "descriptionFr",
    "descriptionNl",
    "descriptionEn",
    "urlBannerFr",
    "urlBannerNl",
    "urlBannerEn",
    "eventDate",
    "slotsCount",
    "client",
    "memberPrice",
    "nonMemberPrice",
    "languages",
    "isReplayable",
    "accreditationHours",
    "status",
    "replayStatus",
    "labelFr",
    "labelNl",
    "labelEn",
    "isVirtual",
    "speakers-abstract",
    "tag",
  ];
  const FFF_ID = 9;
  const filter = [];

  if (pageSize && searchWord) {
    filter.push({
      property: "search",
      value: searchWord,
      operator: "eq",
    });
  }
  const requestUrl = `/event/event/get-list-for-user`;
  const params = {
    period: period,
    communityIds: FFF_ID,
    access_token: token,
    fields: fields.join(","),
    languages:
      languages && Array.isArray(languages) ? languages.join(",") : languages,
    filter: JSON.stringify(filter),
    tags: tags && Array.isArray(tags) ? tags.join(",") : tags,
    limit: pageSize,
    start: (paginationPage - 1) * pageSize,
  };

  return axios.get(requestUrl, { params });
};

export const getEventsTags = ({ token, lng = "fr", searchWord = null }) => {
  const requestUrl = `/event/event/get-tags`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      searchWord: searchWord,
      searchProperty: `name_${lng.toLowerCase()}`,
    },
  });
};

export const getEventOptions = (token, idEvent) => {
  const requestUrl = `/event/option`;

  const filter = [
    {
      property: "event.id",
      value: idEvent,
      operator: "in",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
    },
  });
};

export const getEventPlans = (token, idEvent) => {
  const requestUrl = `/event/plan`;

  const filter = [
    {
      property: "event.id",
      value: idEvent,
      operator: "in",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
    },
  });
};

export const prepareContactFilters = (data) => {
  const {
    language,
    groupsIn,
    groupsNin,
    recipientType,
    clientId,
    mailingLists,
  } = data;
  let filter = [];
  if (recipientType === "CONTACT") {
    filter = [
      {
        property: "contactClient.client",
        value: clientId,
        operator: "eq",
      },
      {
        property: "contactClient.receiveNewsletters",
        value: 1,
        operator: "eq",
      },
    ];
  } else {
    filter = [
      {
        property: "client",
        value: clientId,
        operator: "eq",
      },
      {
        property: "receiveNewsletters",
        value: 1,
        operator: "eq",
      },
    ];
  }
  if (language) {
    filter.push({
      property: "language",
      value: language,
      operator: "eq",
    });
  }
  if (groupsIn) {
    filter.push({
      property: "group.id",
      value: groupsIn,
      operator: "in",
    });
  }
  if (groupsNin) {
    filter.push({
      property: "group.id",
      value: groupsNin,
      operator: "nin",
    });
  }
  if (mailingLists && mailingLists.length > 0) {
    filter.push(
      {
        property: "mailingListSubscribers.mailingList",
        value: mailingLists,
        operator: "in",
      },
      {
        property: "mailingListSubscribers.status",
        value: "ACTIVE",
        operator: "eq",
      },
    );
  }

  return filter;
};

export const getEventSpeakers = (
  token,
  { event, pageSize, paginationPage, searchWord },
) => {
  const requestUrl = `/event/speaker`;
  const filter = [
    {
      property: "event.id",
      value: event,
      operator: "eq",
    },
  ];

  if (searchWord) {
    filter.push({
      property: "lastName",
      value: searchWord,
      operator: "like",
    });
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      limit: pageSize,
      start: (paginationPage - 1) * pageSize,
    },
  });
};

export const getSurveys = (token, clientId, { searchWord, surveyId }) => {
  const requestUrl = `/survey/survey`;
  const fields = ["id", "title", "languages", "latestInstance", "emails_types"];
  const filter = [
    {
      property: "organization.id",
      value: clientId,
      operator: "eq",
    },
  ];

  if (surveyId) {
    filter.push({
      property: "id",
      value: surveyId,
      operator: "eq",
    });
  } else if (searchWord) {
    filter.push({
      property: "title",
      value: searchWord,
      operator: "like",
    });
  }

  const sort = [
    {
      property: "latestInstanceDate",
      dir: "DESC",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
      filter: JSON.stringify(filter),
      limit: 8,
      sort: JSON.stringify(sort),
    },
  });
};

export const getCouponModel = (token, event) => {
  const requestUrl = `/event/coupon-model`;
  const filter = [
    {
      property: "event.id",
      value: event,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      nolimit: 1,
    },
  });
};

export const getClientFolders = (token, idClient) => {
  const requestUrl = `/organization/folder`;
  const fields = ["id", "name", "abbreviation"];

  const filter = [
    {
      property: "owner",
      value: idClient,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
      filter: JSON.stringify(filter),
      organization_id: idClient,
      nolimit: 1,
    },
  });
};
export const getContacts = (token, contactsId) => {
  const requestUrl = `/organization/user`;

  let filter = [
    {
      property: "role.organization",
      value: contactsId,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      app: "EMAILING",
      organization_id: contactsId,
      nolimit: 1,
    },
  });
};

export const getMyClientFolders = (token, idClient, authId) => {
  const requestUrl = `/authorization/findFoldersByUserFolderRole`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: idClient,
      users: [authId],
      nolimit: 1,
    },
  });
};

export const getCupboards = (token, clientId) => {
  const requestUrl = `/organization/folder/get-cupboard-list/${clientId}`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};
export const getExternalType = (token, clientId) => {
  const requestUrl = `/organization/folder/get-external-type-list/${clientId}`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};
export const getProfession = (token, clientId) => {
  const requestUrl = `/organization/folder/get-profession-list/${clientId}`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};
