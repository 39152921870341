export const MENUS = {
  GUEST: ["HOME"],
  CONTACT: ["HOME", "CLIENTS", "E_BOX", "OTHER_EBOX"],
  USER: [
    "HOME",
    "DASHBOARD",
    "CAMPAIGN_EMAIL",
    "CREATE_CAMPAIGN_EMAIL",
    "CLIENTS",
    "E_BOX",
    "CAMPAIGN_SMS",
    "CREATE_CAMPAIGN_SMS",
  ],
  ADMIN: [
    "HOME",
    "CLIENTS",
    "DASHBOARD",
    "CAMPAIGN_EMAIL",
    "CREATE_CAMPAIGN_EMAIL",
    "THEMES_LIST",
    "GENERIC_EMAILING",
    "NEWS_LETTER",
    "SMS_BILLS",
    "CAMPAIGN_SMS",
    "CREATE_CAMPAIGN_SMS",
    "MAILING_LISTS",
    "E_BOX",
    "OTHER_EBOX",
    "NOTIFICATIONS",
    "EMAIL_COMPOSER",
  ],
};

export const STEPS = {
  CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
  SELECT_SAMPLE: "SELECT_SAMPLE",
  MANAGE_THEME: "MANAGE_THEME",
  THEME_PREVIEW: "THEME_PREVIEW",
  SELECT_NEWS: "SELECT_NEWS",
  MANAGE_TEXT_MODEL: "MANAGE_TEXT_MODEL",
  THEMES_LIST: "THEMES_LIST",
  E_BOX: "E_BOX",
  HIDE_MENU: "HIDE_MENU",
};

export const LIST_PAGE_SIZES = [10, 25, 50, 100];
export const LIST_PAGE_SIZES_NEWS_LETTER = [15, 25, 50, 100];
export const LIST_PAGE_SIZES_MAILING_LISTS = [8, 16, 40, 80];

export const LIST_PAGE_SIZES_UA_RECIPIENTS_LIST = [20, 40, 60, 80, 100];

export const CAMPAIGN_STATUS = [
  { label: "DRAFT", value: "DRAFT", color: "#6D7F92" },
  {
    label: "IN PROGRESS",
    value: ["CREATED", "PAUSE", "RECIPIENT_LIST_CREATED"],
    color: "#F7953E",
  },
  { label: "SENT", value: "SENT", color: "#62DEB0" },
  { label: "HALT", value: "HALT", color: "#FB1F5B" },
  { label: "scheduled sending", value: "SCHEDULED_SENDING", color: "#6CA4EA" },
];

export const RECIPIENTS_STATUS = [
  { title: "deliveredTo", value: "DELIVERED_TO", color: "#2495E1" },
  { title: "emailsOpen", value: "EMAILS_OPEN", color: "#02AF8E" },
  { title: "linkClicks", value: "LINK_CLICKS", color: "#6CA4EA" },
  { title: "Undelivered", value: "REFUSED", color: "#FE3745" },
  { title: "Spam", value: "SPAM", color: "#F7953E" },
  { title: "Unsubscribe", value: "UNSUBSCRIBE", color: "#E7C229" },
  { title: "permanent_bounce", value: "PERMANENT", color: "#F31431" },
  { title: "old_permanent_bounce", value: "OLD_PERMANENT", color: "#cc3300" },
  { title: "transient_bounce", value: "TRANSIENT", color: "rgb(86, 194, 76)" },
  {
    title: "undetermined_bounce",
    value: "UNDETERMINED",
    color: "rgb(148, 148, 148)",
  },
];

export const BOUNCE_TYPES = [
  "PERMANENT",
  "OLD_PERMANENT",
  "TRANSIENT",
  "UNDETERMINED",
];

export const SMS_RECIPIENTS_STATUS = [
  { title: "Sent to", value: "SENT_TO", color: "#3182bd" },
  { title: "In progress", value: "IN_PROGRESS", color: "#f3af2d" },
  { title: "Delivered to", value: "DELIVERED_TO", color: "#2ca25f" },
  { title: "unsent", value: "UNSENT", color: "#de2d26" },
  { title: "linkClicks", value: "SMS_LINK_CLICKS", color: "#8856a7" },
  { title: "Buffered", value: "BUFFERED", color: "#d95f0e" },
];

export const CHARTS_COLORS = new Map([
  ["averageScore", "#2495E1"],
  ["totalClicks", "#F7953E"],
  ["totalEmailsOpen", "#FFC43A"],
  ["subscribersNumber", "#3C80DA"],
  ["unsubscribersNumber", "#F84054"],
  ["totalVeryActive", "#FFC43A"],
  ["totalNotActive", "#FFC43A"],
  ["totalModeratelyActive", "#FFC43A"],
  ["totalLowActive", "#FFC43A"],
  ["campaigns", "#3C80DA"],
  ["bouncesPermanent", "#F7953E"],
  ["bouncesOldPermanent", "#F9626D"],
  ["bouncesTransient", "#18A0FB"],
  ["bouncesUndetermined", "#8856a7"],
]);

export const SMS_CREDIT_REQUESTS_STATUS = ["PENDING", "VALIDATED", "REJECTED"];

export const SMS_BILLS_TYPE = ["DEBIT", "CREDIT"];

export const SMS_BILLS_STATUS = ["VALIDATED", "REJECTED", "SENT", "PENDING"];

export const CAMPAIGN_TYPES = {
  web: "WEB_PAGE",
  text: "PLAIN_TEXT",
  dnd: "DND_TEMPLATE",
};

export const CAMPAIGN_LANGUAGE = {
  fr: "french",
  en: "english",
  nl: "dutch",
  all: "all",
};

export const MANUAL_RECIPIENT_TYPE = "MANUAL";

export const EVENT_RECIPIENT_TYPES = [
  "GUEST",
  "PARTNER_GUEST",
  "SPEAKER",
  "PARTNER",
  "CYCLE_GUEST",
];
export const MEMBER_RECIPIENT_TYPES = [
  "MANUAL",
  "CONTACT",
  "COLLABORATOR",
  "FOLDER",
];
export const SURVEY_RECIPIENT_TYPES = ["SURVEY_TARGETS"];
export const PAYMENT_RECIPIENT_TYPES = ["PAYMENT_REMINDER"];

export const MEMBER_UA_RECIPIENT_TYPES = [
  "MANUAL",
  "COLLABORATOR",
  "CLIENT",
  "CONTACT_CLIENT",
  "CONTACT",
];

export const RECIPIENTS_STEP_APPLICATION = [
  {
    id: "COMMUNITY",
    label: "Community",
  },
  { id: "OFFFCOURSE", label: "oFFFcourse" },
  {
    id: "PAYMENT",
    label: "Payment",
  },
  { id: "SURVEY", label: "Survey" },
];

export const CAMPAIGN_RECIPIENTS_TYPE = [
  ...MEMBER_RECIPIENT_TYPES,
  ...EVENT_RECIPIENT_TYPES,
  ...PAYMENT_RECIPIENT_TYPES,
  ...SURVEY_RECIPIENT_TYPES,
];

export const NOTIFICATION_LANGUAGE = [
  { key: "fr", value: "french" },
  { key: "en", value: "english" },
  { key: "nl", value: "dutch" },
];

export const SMS_CAMPAIGN_RECIPIENTS_TYPE = ["CONTACT", "MANUAL"];

export const SMS_MAX_CHARACTERS = [
  160, 306, 459, 612, 765, 918, 1071, 1224, 1377,
];

export const SMS_CAMPAIGN_DYNAMIC_FIELDS = [
  { name: "first name", value: " (FIRST_NAME)" },
  { name: "last name", value: " (LAST_NAME)" },
];

export const RECIPIENT_ATTRIBUTE = {
  CONTACT: {
    email: "mainClientEmail",
    firstName: "firstName",
    lastName: "lastName",
    phoneNumber: "mobileClientPhone",
    countryCode: "countryCode",
  },
  OECCBB_MEMBERS: {
    email: "mainEmail",
    firstName: "firstName",
    lastName: "lastName",
  },
  PROSPECT: { email: "email", firstName: "firstName", lastName: "lastName" },
  COLLABORATOR: {
    email: "mainEmail",
    firstName: "firstName",
    lastName: "lastName",
  },
  PAYMENT_REMINDER: {
    email: "mainEmail",
    firstName: "firstName",
    lastName: "lastName",
  },
  FOLDER: { email: "mainEmail", firstName: "firstName", lastName: "lastName" },
  SPEAKER: { email: "email", firstName: "firstName", lastName: "lastName" },
  CYCLE_GUEST: {
    email: "mainEmail",
    firstName: "firstName",
    lastName: "lastName",
  },
  GUEST: { email: "mainEmail", firstName: "firstName", lastName: "lastName" },
  PARTNER: { email: "email", firstName: null, lastName: "name" },
  MAILING_LIST: {
    email: "email",
    firstName: "firstName",
    lastName: "lastName",
  },
  PARTNER_GUEST: {
    email: "email",
    firstName: "firstName",
    lastName: "lastName",
  },
  FIDUCIARIES_CLIENT: {
    email: "mainEmail",
    firstName: "firstName",
    lastName: "lastName",
  },
  SURVEY_TARGETS: {
    email: "email",
    firstName: "firstName",
    lastName: "lastName",
  },
  CLIENT: { email: "mainEmail", firstName: "firstName", lastName: "lastName" },
  CONTACT_CLIENT: {
    email: "mainEmail",
    firstName: "firstName",
    lastName: "lastName",
  },
};

export const PARTNER_TYPES = [
  { id: "1", label: "STRUCTUREL" },
  { id: "2", label: "EXPOSANT" },
  { id: "3", label: "SOUTIEN" },
  { id: "4", label: "MEDIA" },
];

export const GUEST_STATUS = [
  { id: "1", name: "données personnelles" },
  { id: "2", name: "choix de la formule" },
  { id: "3", name: "données de facturation" },
  { id: "4", name: "paiement" },
  { id: "5", name: "confirmation" },
  { id: "6", name: "a reconfirmer" },
  { id: "7", name: "annulé" },
  { id: "100", name: "avec Programme" },
  { id: "101", name: "gratuits" },
  { id: "102", name: "payants" },
  { id: "103", name: "a rembourser" },
  { id: "104", name: "impayés" },
  { id: "105", name: "badges imprimés" },
  { id: "106", name: "venus" },
];

export const AUTOMATION_TIME_STATUS = [
  { label: "Days before the event", value: "Days before the event" },
  { label: "Hours before the event", value: "Hours before the event" },
  { label: "Days after the event", value: "Days after the event" },
  { label: "Hours After the event", value: "Hours after the event" },
];

export const BE_BASIC_SMS_PRICE = 0.065;
export const DEFAULT_CLIENT_MARGE = 35;

export const DRAG_DROP_ORIENTATION_OPTIONS = [
  { label: "social_orientation_row", value: "row" },
  { label: "social_orientation_column", value: "column" },
];
export const DRAG_DROP_THEME_TYPE_OPTIONS = [
  { label: "social_theme_type_logo", value: "logo" },
  { label: "social_theme_type_square", value: "square" },
  { label: "social_theme_type_rounded", value: "rounded" },
  { label: "social_theme_type_circle", value: "circle" },
];
export const DRAG_DROP_THEME_COLOR_OPTIONS = [
  { label: "social_theme_color_colored", value: "colored" },
  { label: "social_theme_color_colored_bordered", value: "colored-bordered" },
  { label: "social_theme_color_black", value: "black" },
  { label: "social_theme_color_black_bordered", value: "black-bordered" },
  { label: "social_theme_color_white", value: "white" },
  { label: "social_theme_color_white_bordered", value: "white-bordered" },
];

export const SORT_OPTIONS = [
  { label: "oldest first", value: "asc" },
  { label: "newest first", value: "desc" },
];

export const THEME_ORIGINALITY = ["original", "replication"];

export const THEME_TYPES = [
  { label: "drag & drop", value: "DRAG_AND_DROP" },
  { label: "simple", value: "SIMPLE" },
  { label: "wrapper", value: "WRAPPER" },
];

export const SCOPE_TYPES = [
  { label: "library", value: "TAMTAM", color: "#62DEB0" },
  { label: "my models", value: "COMMUNITY", color: "#FF5656" },
];

export const LANGUAGE_FLAGS = {
  fr: "flag-icon-fr",
  nl: "flag-icon-nl",
  en: "flag-icon-gb",
};

export const IEC_ID = 8;

export const SVG_ICONS = {
  //MainMenu
  DIRECTORY: "directory.svg",
  NOTIFS: "notifs.svg",
  APPS: "apps.svg",
  EBOX: "ebox.svg",
  PORTAL: "portal.svg",

  // SubMenu
  EMAIL_LIGHT: "EMAIL-light.svg",
  DASHBOARD_LIGHT: "dashboard-light.svg",
  EMAIL_CREATE_LIGHT: "EMAIL-create-light.svg",
  GENERIC_EMAIL_LIGHT: "generic-email-light.svg",
  TEMPLATES_LIGHT: "templates-light.svg",
  SMS_LIGHT: "SMS-light.svg",
  CREATE_LIGHT: "SMS-create-light.svg",
  FINANCES_SMS_LIGHT: "finances-sms-light.svg",
  MAILBOX_LIGHT: "mailbox-light.svg",
  OTHER_EBOX_LIGHT: "other-ebox-light.svg",
  NOTIFICATIONS_LIGHT: "notifications-light.svg",
  NEWS_LETTER_LIGHT: "nl_menu.svg",
  MAILING_LIST_LIGHT: "mailing-list-light.svg",
  INBOX_LIGHT: "inbox-light.svg",
  CALENDAR_NEWSLETTER: "calendar-newsletter.svg",

  //Page Header
  DASHBOARD: "dashboard.svg",
  INBOX: "inbox.svg",
  SMS: "SMS.svg",
  SETTING: "settings.svg",
  EDIT_EMAIL: "edit-email.svg",
  EDIT_SMS: "SMS-create.svg",
  FINANCES_SMS: "finances-sms.svg",
  TEMPLATES: "templates.svg",
  GENERIC_EMAIL: "generic-email.svg",
  NOTIFICATIONS: "notifications.svg",
  MAILBOX: "mailbox.svg",
  OTHER_EBOX: "other-ebox.svg",
  NEWS_LETTER: "News_Letter.svg",
  MAILING_LIST: "mailing-list.svg",
  EMAIL: "EMAIL.svg",
  APPROVAL: "approval.svg",

  //Theme actions
  PREVIEW: "preview.svg",
  DUPLICATE: "duplicate.svg",
  EDIT: "edit.svg",
  DELETE: "delete.svg",
  SHARE: "share.svg",
  STOP_SHARE: "not-share.svg",
  LINK: "link.svg",
  PLUS: "plus.svg",

  // Type Selectors
  DRAG_DROP: "drag_drop.svg",
  NEWSLETTER: "newsletter.svg",
  TEXT_EDITOR: "text_editor.svg",
  FROM_URL: "from_url.svg",

  //Others
  TRASH: "trash.svg",
  EYE: "eye.svg",
  TEST: "test.svg",
  PENCIL: "pencil.svg",
  COPY: "copy.svg",
  OPEN_LINK: "open-link.svg",
  CLOSE: "close.svg",
  ARROW_LEFT: "arrow-left.svg",
  ADD: "add.svg",
  UNLOCK: "unlock.svg",
  ALERT: "alert.svg",
  TAG: "tag.svg",
  PRIVATE: "private.svg",
  NEXT: "next.svg",
  CHECK: "check.svg",
};

export const NOTIFICATIONS_APPS = ["EVENT", "TERMINAL"];

export const EMAIL_SENT_STATUS = [
  { title: "delivered", value: "DELIVERED_TO", color: "#02AF8E" },
  { title: "emailsOpen", value: "EMAILS_OPEN", color: "#2495E1" },
  { title: "undelivered", value: "REFUSED", color: "#FE3745" },
  { title: "Spam", value: "SPAM", color: "#F7953E" },
  { title: "linkClicks", value: "LINK_CLICKS", color: "#6CA4EA" },
];

export const USER_ROLE_TYPES = [
  "LEGAL_REPRESENTATIVE",
  "MANAGER",
  "OFFICIAL",
  "EXTERNAL",
];
export const USER_ROLE_TYPE_STATUS = ["ADMIN", "MANAGER"];

export const LANGUAGES = ["fr", "nl", "en"];

export const TTP_LOGO_URL = "/img/home/logo.svg";
export const COMMUNITY_NAME_MAX_LENGTH = 20;
export const COMMUNITY_DEFAULT_LOGO_URL = "/img/default-logo.png";

export const GRAVITY_OPTIONS = [
  "North",
  "South",
  "East",
  "West",
  "Centre",
  "Smart",
];
export const FILTER_OPTIONS = ["Blur", "BW", "Sharp"];

export const UA_RECIPIENT_ATTRIBUTE = {
  CONTACT: {
    email: "mainClientEmail",
    firstName: "firstName",
    lastName: "lastName",
    phoneNumber: "mobileClientPhone",
    countryCode: "countryCode",
    groups: "groups",
  },
  COLLABORATOR: {
    email: "mainEmail",
    firstName: "firstName",
    lastName: "lastName",
  },
  CLIENT: { email: "mainEmail", firstName: "firstName", lastName: "lastName" },
  CONTACT_CLIENT: {
    email: "mainEmail",
    firstName: "firstName",
    lastName: "lastName",
    organizationName: "folderName",
    groups: "groups",
  },
};
