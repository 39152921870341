import { combineReducers } from "redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import { connectRouter } from "connected-react-router";
import { getHistory } from "../router/getHistory";

import {
  activitiesReducer,
  authReducer,
  campaignsReducer,
  currentEmailCampaignReducer,
  currentSmsCampaignReducer,
  campaignsSortReducer,
  campaignStatsReducer,
  currentNavPageReducer,
  emailsReducer,
  eventsReducer,
  formulasReducer,
  groupsReducer,
  languageReducer,
  subMenuDirectionReducer,
  optionsReducer,
  recipientsReducer,
  recipientsStatsFilterReducer,
  recipientsStatsReducer,
  slotsReducer,
  formationsReducer,
  speakersReducer,
  sourcesTokensReducer,
  textModelReducer,
  approvalTeamsReducer,
  approvalReducer,
  dialogReducer,
  phoneNumbersReducer,
  stepReducer,
  setApplication,
  themesReducer,
  mailingListsReducer,
  usersReducer,
  settingsReducer,
  smsBillsReducer,
  smsBillsSortReducer,
  webNotificationsReducer,
  pushNotificationsReducer,
  currentNotificationReducer,
  eBoxReducer,
  eBoxAttachmentsReducer,
  eboxWidgetReducer,
  mediaReducer,
  articlesReducer,
  contactsReducer,
  couponModelReducer,
  eboxOwnerReducer,
  emailTypesReducer,
  emailsSettingsReducer,
  currentSettingReducer,
  sesEmailsReducer,
  dynamicsFieldsReducer,
  setWindowHeight,
  eBoxSmsReducer,
  dashboardReducer,
  newslettersUAArticlesReducer,
  uaNewsletterStateReducer,
  campaignBroadcastersReducer,
  cupboardReducer,
  externalTypeReducer,
  professionReducer,
  delegationTypesReducer,
  subscriptionUAReducer,
} from "reducers";
import {
  eventCyclesReducer,
  eventFormatReducer,
  foldersReducer
} from "../reducers/index/filters";
import { editorReducer } from "../reducers/index/editor/editor";

export const createReducer = () => {
  const history = getHistory();
  return combineReducers({
    auth: authReducer,
    params: combineReducers({
      lng: languageReducer,
      step: stepReducer,
      application: setApplication,
      currentNavPage: currentNavPageReducer,
      sourcesTokens: sourcesTokensReducer,
      subMenuDirection: subMenuDirectionReducer,
      sesEmails: sesEmailsReducer,
      dynamicsFields: dynamicsFieldsReducer,
      windowHeight: setWindowHeight,
    }),
    users: usersReducer,
    router: connectRouter(history),
    loadingBar: loadingBarReducer,
    currentCampaign: combineReducers({
      email: currentEmailCampaignReducer,
      sms: currentSmsCampaignReducer,
      uaNewsletter: uaNewsletterStateReducer,
    }),
    campaigns: combineReducers({
      list: campaignsReducer,
      sort: campaignsSortReducer,
      recipients: recipientsReducer,
      campaignBroadcasters: campaignBroadcastersReducer,
      delegationTypes: delegationTypesReducer,
      stats: combineReducers({
        campaign: campaignStatsReducer,
        recipients: combineReducers({
          list: recipientsStatsReducer,
          filter: recipientsStatsFilterReducer,
        }),
      }),
    }),
    themes: themesReducer,
    medias: mediaReducer,
    articles: articlesReducer,
    textModels: textModelReducer,
    newslettersUAArticles: newslettersUAArticlesReducer,
    genericEmail: combineReducers({
      types: emailTypesReducer,
      settings: emailsSettingsReducer,
      currentSetting: currentSettingReducer,
    }),
    mailingLists: mailingListsReducer,

    filters: combineReducers({
      groups: groupsReducer,
      events: eventsReducer,
      contacts: contactsReducer,
      eventCycles: eventCyclesReducer,
      eventFormat: eventFormatReducer,
      speakers: speakersReducer,
      activities: activitiesReducer,
      slots: slotsReducer,
      formations: formationsReducer,
      options: optionsReducer,
      formulas: formulasReducer,
      couponModels: couponModelReducer,
      folders: foldersReducer,
      cupboards: cupboardReducer,
      externalTypes: externalTypeReducer,
      professions: professionReducer,
    }),
    emails: emailsReducer,
    phoneNumbers: phoneNumbersReducer,
    settings: settingsReducer,
    approval: combineReducers({
      list: approvalReducer,
      teams: approvalTeamsReducer,
    }),
    TTPDialog: dialogReducer,
    smsBills: combineReducers({
      list: smsBillsReducer,
      sort: smsBillsSortReducer,
    }),
    notifications: combineReducers({
      web: webNotificationsReducer,
      push: combineReducers({
        list: pushNotificationsReducer,
        currentNotification: currentNotificationReducer,
      }),
    }),
    ebox: combineReducers({
      owner: eboxOwnerReducer,
      list: eBoxReducer,
      attachmentsList: eBoxAttachmentsReducer,
      widget: eboxWidgetReducer,
      smsList: eBoxSmsReducer,
    }),
    dashboard: dashboardReducer,
    editor: editorReducer,
    subscriptionUA: subscriptionUAReducer,
  });
};
