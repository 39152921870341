import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';

import {saveApprovalResponse} from 'thunks';
import {onSuccess, onError} from 'utils';
import _ from 'i18n';


class RejectCampaign extends Component {

  constructor(props) {
    super(props);
    this.state = {attachment: null, reason: ""};
    this.handleReasonChange = (e) => this.setState({reason: e.target.value});
    this.handleAttachmentChange = (attachment) => this.setState({attachment});
  }

  handleDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length === 0) return;
    this.setState({attachment: acceptedFiles[0]});
  }

  saveRejection = () => {
    let {attachment, reason} = this.state;
    let {id} = this.props;

    if (!reason || reason.trim().length == 0) {
      return;
    }
    this.props.dispatch(saveApprovalResponse({
      attachment,
      reason,
      id,
      decision: "deny"
    }, this.props.handleSetCurrentPage))
      .then(res => {
          onSuccess(res,
            {
              body: _('Your view is taken into consideration'),
              title: _('Thank you for your collaboration')
            });
        },
        err => onError(err)
      );
  }

  render() {
    let {reason, attachment} = this.state;
    let {version, updating, emailAutomationCampaignConfigCompletion} = this.props;

    return <div className="align-center">
      <h4
        className="text-center">{emailAutomationCampaignConfigCompletion ? _('You are about to reject the completion of the campaign, please specify the reason') : _('You are about to reject the campaign, please specify the reason')} ({_('version')} #{version})</h4>
      <div className="column param-label">{_('reason')}<span
        className="obligatory">*</span></div>
      <div className="column">
        <textarea
          onChange={this.handleReasonChange}
          rows="3"
          value={reason}/>
      </div>
      <div className="column param-label">{_('attachment')}</div>
      <div className="column">
        <div className="attachments-container">
          <Dropzone className="attachments-dropzone"
                    onDrop={this.handleDrop.bind(this)}
                    multiple={false}
                    accept={'image/jpeg, image/png'}
          >
            <div>
              <span className="icon icon-cloud-upload"></span>
              {(attachment) ?
                <div>{attachment.name}
                  <span className="icon icon-trash" onClick={() => {
                    this.handleAttachmentChange(null);
                    return false;
                  }}></span>
                </div> :
                <p>{_("Drag and drop a screenshot here, or click to download.")}</p>
              }
            </div>
          </Dropzone>
        </div>
      </div>
      <div className="column text-center buttons-block">
        <button className="btn secondary"
                onClick={() => this.props.isUaNewsletter ? this.props.handleSetCurrentPage("LIST_PAGE") : this.props.history.push("/")}
        >{_('cancel')}
        </button>
        <button className="btn primary"
                onClick={!updating && this.saveRejection}>{updating ? _('Processing...') : _('save')}</button>
      </div>
    </div>;
  }
}

const mapStateToProps = store => ({
  updating: store.approval.list.updating,
  isUaNewsletter: store.currentCampaign.uaNewsletter.isUaNewsletter,
})

export default compose(
  connect(mapStateToProps),
  withRouter
)
(RejectCampaign);
