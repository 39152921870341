import * as actions from "actions";
import {
  getGroups,
  getClientEvents,
  getEventActivities,
  getEventSlot,
  getEventFormation,
  getEventOptions,
  getEventPlans,
  getEventSpeakers,
  getSurveys,
  getClientPartnerEvents,
  getCouponModel,
  getEventCycles,
  getClientFolders,
  getContacts,
  getCupboards,
  getExternalType,
  getProfession,
} from "TamtamAPI";
import {
  getEventFormat,
  getEventsTags,
  getMyClientFolders
} from "../../api/TamtamAPI/filters";

export const fetchGroups = (recipientType) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;

    return dispatch(
      actions.fetchGroups(getGroups(token, clientId, recipientType)),
    );
  };
};

export const fetchEvents = (
  eventId = null,
  isGenericEmail = false,
  searchWord,
) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    let language = !isGenericEmail
      ? getState().currentCampaign.email.language
      : getState().params.lng;

    return dispatch(
      actions.fetchEvents(
        getClientEvents(token, clientId, { eventId, searchWord, language }),
      ),
    );
  };
};

export const fetchPartnerEvents = (eventId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;

    return dispatch(
      actions.fetchEvents(getClientPartnerEvents(token, clientId, eventId)),
    );
  };
};

export const fetchEventCycles = (cycleId = null, isGenericEmail = false) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    let language = !isGenericEmail
      ? getState().currentCampaign.email.language
      : getState().params.lng;

    return dispatch(
      actions.fetchEventCycles(
        getEventCycles(token, clientId, cycleId, language),
      ),
    );
  };
};

export const fetchEventFormat = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    return dispatch(
      actions.fetchEventFormat(
        getEventFormat(token, clientId),
      ),
    );
  };
};

export const fetchEventActivities = (eventId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.fetchActivities(getEventActivities(token, eventId)),
    );
  };
};

export const fetchEventSlot = (eventId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let filter = eventId ? { event: eventId } : getState().filters.slots.filter;

    return dispatch(actions.fetchSlots(getEventSlot(token, filter)));
  };
};

export const fetchEventFormation = (
  trainingType,
  eventId = null,
  searchWord,
  languages = null,
  tags = null,
) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const { pageSize, paginationPage, formationPages } =
      getState().filters.formations.filter;

    return dispatch(
      actions.fetchFormations(
        getEventFormation(token, trainingType, searchWord, languages, tags, {
          pageSize,
          paginationPage,
          formationPages,
        }),
      ),
    );
  };
};

export const fetchEventsTagsSuggestions = ({ searchWord, lng }) => {
  return (dispatch, getState) => {
    let { token } = getState().auth;
    return dispatch(
      actions.fetchEventsTagsSuggestions(
        getEventsTags({ token, searchWord, lng }),
      ),
    );
  };
};

export const fetchEventOptions = (eventId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.fetchOptions(getEventOptions(token, eventId)));
  };
};

export const fetchEventPlan = (eventId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.fetchPlan(getEventPlans(token, eventId)));
  };
};

export const fetchEventSpeakers = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const { filter } = getState().filters.speakers;

    return dispatch(actions.fetchSpeakers(getEventSpeakers(token, filter)));
  };
};

export const fetchSurveys = (surveyId, searchWord) => (dispatch, getState) => {
  const { token } = getState().auth;
  const clientId = getState().auth.currentClient.id;

  return dispatch(
    actions.fetchSurveys(getSurveys(token, clientId, { surveyId, searchWord })),
  );
};

export const fetchCouponModels = (event) => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(actions.fetchCouponModel(getCouponModel(token, event)));
};

export const fetchClientFolders = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;

    return dispatch(
      actions.fetchClientFolders(getClientFolders(token, clientId)),
    );
  };
};
export const fetchContacts = (contactsId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.fetchContacts(getContacts(token, contactsId)));
  };
};
export const fetchMyClientFolders = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    let authId = getState().auth.user.id;

    return dispatch(
      actions.fetchMyClientFolders(getMyClientFolders(token, clientId, authId)),
    );
  };
};

export const fetchCupboards = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;

    return dispatch(actions.fetchCupboards(getCupboards(token, clientId)));
  };
};
export const fetchExternalType = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;

    return dispatch(
      actions.fetchExternalType(getExternalType(token, clientId)),
    );
  };
};
export const fetchProfession = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;

    return dispatch(actions.fetchProfession(getProfession(token, clientId)));
  };
};
