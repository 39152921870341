import React, { PureComponent } from "react";
import Select, { components } from 'react-select';
import TagsInput from 'react-tagsinput';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'

import TTPSelect from "common/TTPSelect";

import _ from 'i18n';
import { CAMPAIGN_LANGUAGE } from "Common";
import { getMainEmail } from 'utils';
import { fetchSesEmailsStatuses } from 'thunks';
import RecipientType from "./RecipientType";
import { IS_EMAIL_VALID } from "../../../services/svgIcones";
import Dropzone from "react-dropzone";
import { ACCEPT_MIME_TYPES } from "../../../constants";
import AttachmentsContainer from "../../common/AttachmentsContainer";
import {NotificationManager} from "react-notifications";
import { setCurrentEmailCampaignData } from "../../../actions/actions";
import {
  isValidEmail,
  hasValidLength,
  ucFirst,
} from "../../../services/utils";
import {fetchEvents} from "../../../actions/thunks/filters";

@connect((store) => {
  return {
    client: store.auth.currentClient,
    registeredEmails: store.params.sesEmails.items,
    sesEmailsFetched: store.params.sesEmails.fetched,
    opened: store.currentCampaign.email.currentSideBar === "PARAMS",
    recipientType: store.currentCampaign.email.recipientType,
    language: store.currentCampaign.email.language,
    replyTo: store.currentCampaign.email.replyTo,
    fromName: store.currentCampaign.email.fromName,
    from: store.currentCampaign.email.from,
    attachments: store.currentCampaign.email.attachments,
    deletedAttachmentsIds: store.currentCampaign.email.deletedAttachmentsIds,
    verifiedEmails: store.auth.currentSetting.verifiedEmails,
    filters: store.filters,
    eventFilters: store.currentCampaign.email.eventFilters
  }
})
export default class ParamsTab extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      registrationStatus: 'FAILED',
      tag: "",
      hasError: {
        from: false,
        fromName: false,
        replyTo: false
      }
    };
  }

  componentDidMount() {
    const { dispatch, sesEmailsFetched, from, verifiedEmails } = this.props;
    if (sesEmailsFetched) {
      this.updateSesEmailStatus();
    } else {
      dispatch(fetchSesEmailsStatuses());
    }
    if (!from) {
      const mainEmail = getMainEmail(verifiedEmails);
      this.handleFromChange(mainEmail);
      this.updateSesEmailStatus();
    }
  }

  componentDidUpdate(prevProps) {
    const { registeredEmails, from, language, filters, eventFilters, recipientType } = this.props;
    if (prevProps.registeredEmails !== registeredEmails || from !== prevProps.from) {
      this.updateSesEmailStatus();
    }
    if(prevProps.language !== language && (recipientType === "CONTACT" && filters.events.items.length > 0 || ["SPEAKER", "GUEST", "PARTNER", "PARTNER_GUEST"].indexOf(recipientType)  && eventFilters.length > 0)) {
      this.props.dispatch(fetchEvents());
    }
  }

  handleLanguageChange = (language) => {
    this.props.dispatch(setCurrentEmailCampaignData({ language }));
  }

  renderLang() {
    const { client, language } = this.props;
    let defaultLanguages = Object.keys(CAMPAIGN_LANGUAGE);
    let preferences = defaultLanguages;
    let options = [];
    if (preferences.indexOf('all') === -1) {
      preferences.push('all');
    }
    for (let i = 0; i < preferences.length; i++) {
      let lang = preferences[i];
      options.push({ id: lang, label: ucFirst(_(CAMPAIGN_LANGUAGE[lang])) });
    }
    return <TTPSelect
      simple={true}
      notClearable={true}
      values={language}
      placeholder={_('lang')}
      options={options}
      onChange={this.handleLanguageChange} />
  }

  onSuccess(res) {
    const { value } = res;
    if (res.value instanceof Error) {
      NotificationManager.error(_('incompleteOperation'), _('error'));
    } else if (value.data.result === "OK") {
      this.setState({ registrationStatus: 'PENDING' });
    }
  }

  updateSesEmailStatus = () => {
    let { registeredEmails, from } = this.props;
    let registrationStatus = "FAILED";
    for (let key in registeredEmails) {
      if (key === from) {
        registrationStatus = registeredEmails[key].VerificationStatus.toUpperCase();
        break;
      }
    }
    this.props.dispatch(setCurrentEmailCampaignData({
      fromEmailValid: registrationStatus === "SUCCESS"
    }));
    this.setState({ registrationStatus });
  }

  handleChangeInput(tag) {
    let tagPattern = new RegExp(TAG_REGEX);
    this.setState(prevState => ({
      tag,
      hasError: {
        ...prevState.hasError,
        replyTo: tag && !tagPattern.test(tag),
      }
    }));
  }

  handleFromChange = (fromData) => {
    const from = fromData?.email || "";
    const fromName = fromData?.name || "";

    this.props.dispatch(setCurrentEmailCampaignData({
      from,
      fromName
    }));

    this.setState(prevState => ({
      hasError: {
        ...prevState.hasError,
        from: !isValidEmail(from),
        fromName: !hasValidLength(fromName, 3)
      }
    }))
  }

  handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;
    const newAttachment = acceptedFiles[0];
    const maxSizeInBytes = 10485760; // 10 MB

    // Calculate the total size of all attachments including the new one
    const totalSize = this.props.attachments.reduce((acc, file) => acc + file.size, 0) + newAttachment.size;

    if (totalSize > maxSizeInBytes) {
      NotificationManager.error(_('max_file_size_allowed_is_for_added_files_10MB'), _("Error"));
      return;
    }

    let attachments = [...this.props.attachments];
    attachments.push(newAttachment);

    this.props.dispatch(setCurrentEmailCampaignData({ attachments }));
  }

  handleDeleteAttachment = (index, id) => {
    let attachments = [...this.props.attachments];
    let deletedAttachmentsIds = [...this.props.deletedAttachmentsIds];

    if (attachments[index]) {
      attachments = attachments.filter((_, i) => i !== index);
    }
    if (id) {
      deletedAttachmentsIds.push(id);
    }

    this.props.dispatch(setCurrentEmailCampaignData({ attachments, deletedAttachmentsIds }));
  }

  handleReplyToChange = (replyTo) => {
    this.props.dispatch(setCurrentEmailCampaignData({ replyTo }));
  }

  handleFromNameChange = (e) => {
    const fromName = e.target.value;
    this.props.dispatch(setCurrentEmailCampaignData({ fromName }));
    this.setState(prevState => ({
      hasError: {
        ...prevState.hasError,
        fromName: !hasValidLength(fromName, 3)
      }
    }))
  }

  render() {
    const { opened, from, fromName, replyTo, attachments, language, verifiedEmails } = this.props;

    const { registrationStatus, hasError, tag } = this.state;

    const fromCssClass = `${from && !hasError.from && 'filled-box'} ${!from && 'empty-box'} ${hasError.from && 'is-invalid-input'}`;
    const fromNameCssClass = `${fromName && !hasError.fromName && 'filled-box'} ${!fromName && 'empty-box'} ${hasError.fromName && 'is-invalid-input'}`;

    return (
      <div id="campaign-params__tab" className={`${!opened && "hide"}`}>
        <div className="sidebar__form">
          <h4 className="sidebar__header">{_('campaignInfo')}</h4>
          <div className="row">
            <div className="columns small-6 columns-padding">
              <span className="param__title email-field">{_('fromEmail')}
                {IS_EMAIL_VALID(registrationStatus === "SUCCESS")}
              </span>
              <div className="small-10">
                <Select
                  isSearchable
                  placeholder=""
                  value={verifiedEmails.filter(option => option.email === from)}
                  onChange={this.handleFromChange}
                  options={verifiedEmails}
                  components={{ Option: EmailOption }}
                  className={`ttp-select ${fromCssClass}`}
                  classNamePrefix="ttp-select"
                  getOptionValue={(option) => option.email}
                  getOptionLabel={(option) => option.email}
                />
                <span className={hasError.from ? 'form-error is-visible' : 'form-error'}>{_('invalidEmail')}</span>
              </div>
            </div>
            <div className="columns small-6 columns-padding">
              <span className="param__title small-10 margin-left-auto">{_('fromName')}</span>
              <div className="small-10 margin-left-auto">
                <input className={fromNameCssClass} type="text" onChange={this.handleFromNameChange} value={fromName} />
                <span className={hasError.fromName ? 'form-error is-visible' : 'form-error'}> {_('invalidFromName')}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-6 columns-padding">
              <span className="param__title">{_('language')}
                <span className="tooltip-info" data-tip={_('params_lang_info')} data-for='lang-info'>
                  <i className="icon-info" />
                </span>
              </span>
              <ReactTooltip id='lang-info' place='bottom' multiline={true} />
              <div className={`small-10 ${language ? "filled-box" : "empty-box"}`}>
                {this.renderLang()}
              </div>
            </div>
            <div className="columns small-6 columns-padding">
              <span className="param__title small-10 margin-left-auto">{_('replyTo')}</span>
              <div className={`small-10 margin-left-auto ${(replyTo && replyTo.length) ? "filled-box" : "empty-box"}`}>
                <TagsInput
                  value={replyTo}
                  onChange={this.handleReplyToChange}
                  addOnBlur
                  addKeys={[9, 13, 186, 188]}
                  removeKeys={[]}
                  onlyUnique={true}
                  addOnPaste
                  validationRegex={TAG_REGEX}
                  inputProps={{ placeholder: _('emailAdd') }}
                  inputValue={tag}
                  onChangeInput={this.handleChangeInput.bind(this)}
                />
              </div>
              <span className={hasError.replyTo ? 'form-error is-visible' : 'form-error'}>{_('replyToError')}</span>
            </div>
          </div>
          <div className="row">
            <div className="columns columns-padding">
              <span className="param__title">{_('Attachments')}</span>
              <div className="attachments-container">
                <AttachmentsContainer attachments={attachments}
                  onDeleteAttachment={this.handleDeleteAttachment} />
                <Dropzone className="attachments-dropzone"
                  onDrop={this.handleDrop}
                  multiple={false}
                  accept={ACCEPT_MIME_TYPES.join(',')}>
                  <span className="icon icon-cloud-upload" />
                  <p>{_('Drag and drop some files here, or click to select files to upload.')}</p>
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const TAG_REGEX = /^[a-zA-Z\s]*<\s*[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?\s*>$|^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i

const EmailOption = props => {
  const { email, name } = props.data;

  return <components.Option {...props} >
    <div className="label-from">
      <span className="label-from__name">{name}</span>
      <span className="label-from__email">{email}</span>
    </div>
  </components.Option>
};
