import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "i18n";
import "react-datepicker/dist/react-datepicker.css";
import TTPSelect from "common/TTPSelect";
import {
  onError,
  onSuccess,
  SVG_DELETE_ICON, SVG_INFO_ICON,
  SVG_MINUS, SVG_PLUS
} from "../../../services/utils";
import {store} from "../../../index.js";
import {AUTOMATION_TIME_STATUS, GUEST_STATUS} from 'Common';
import TagsInput from "react-tagsinput";
import {
  setAutomationPaginationPage,
  setEventsFilters
} from "../../../actions/actions/currentCampaign";
import {fetchTemplates} from "../../../actions/thunks/theme";
import {TEMPLATE_TYPES} from "constants";
import {fetchTextModels} from "../../../actions/thunks/textModel";
import TTPLoader from "../../ui/TTPLoader";
import {
  deleteAutomationCampaign,
  fetchAutomationCampaignConfigs,
  saveCampaignAutomation
} from "../../../actions/thunks/campaign";
import Viewer from "../../common/Viewer";
import {TTP_API_URL} from "../../../config";
import {setTTPDialogCustomData} from "../../../actions/actions/params";
import DIALOG_TYPES from "../../../constants/TTPDialog";
import TTPTab from "../../ui/tabs/TTPTab";
import {fetchEventFormat} from "../../../actions/thunks/filters";
import { fetchApprovalTeams } from "thunks";
import {TeamOption} from "../../campaign/test/TeamOption";

@connect((store) => {
  return {
    events: store.filters.events.items,
    slots: store.filters.slots.items,
    options: store.filters.options.items,
    formulas: store.filters.formulas.items,
    language: store.params.lng,
    eventFilters: store.currentCampaign.email.eventFilters,
    themes: store.themes?.list?.items,
    themesFetching: store.themes.list?.fetching,
    textModels: store.textModels?.items,
    campaignLanguage: store.currentCampaign.email.language,
    token: store.auth.token,
    automationPageSize: store.currentCampaign.email.automationPageSize,
    automationPaginationPage: store.currentCampaign.email.automationPaginationPage,
    eventFormat: store.filters.eventFormat.items,
    teams: store.approval.teams.items,
    campaignAutomationsFetching: store.currentCampaign.email.fetching,
  }
}, (dispatch, ownProps) => {

  const handleStepsChange = (dispatch, steps, operatorStep, operatorPrinted, operatorConfirmation) => {
    const hasPrinted = steps.includes('105');
    const hasConfirmation = steps.includes('5');
    const otherSteps = steps.filter(step => (step !== '105' && step !== '5'));

    // Get current filters but exclude any registeredChoice filters
    const oppositeOperator = operatorStep === 'in' ? 'nin' : 'in';
    const oppositeFilters = store.getState().currentCampaign.email.eventFilters?.filter(filter =>
      (filter.operator === oppositeOperator && filter.property !== 'registeredChoice') ||
      (filter.operator === (operatorStep === 'in' ? 'neq' : 'eq') && filter.property === 'printed')
    );

    // Clear current step filter
    dispatch(setEventsFilters({
      property: 'step',
      operator: operatorStep,
      value: ''
    }));

    // Clear printed filter if badges imprimés is being removed
    if (!hasPrinted) {
      dispatch(setEventsFilters({
        property: 'printed',
        operator: operatorPrinted,
        value: ''
      }));
    }

    const isNeqRegisteredChoiceStored = store.getState().currentCampaign.email.eventFilters.filter(filter =>
      filter.property === 'registeredChoice' && filter.operator === 'neq' && filter.value === ''
    );
    const isEqRegisteredChoiceStored = store.getState().currentCampaign.email.eventFilters.filter(filter =>
      filter.property === 'registeredChoice' && filter.operator === 'eq' && filter.value === ''
    );

    if (isNeqRegisteredChoiceStored.length === 0 ||
      isNeqRegisteredChoiceStored.length !== 0 && !hasConfirmation && steps.length === 0 && operatorStep === 'in'
      || isNeqRegisteredChoiceStored.length !== 0 && !hasConfirmation && steps.length > 0 && operatorStep === 'in'){
      dispatch(setEventsFilters({
        property: 'registeredChoice',
        operator: 'neq',
        value: null
      }));
    }

    if (isEqRegisteredChoiceStored.length === 0  ||  isEqRegisteredChoiceStored.length !== 0 && !hasConfirmation && steps.length === 0 && operatorStep === 'nin' || isEqRegisteredChoiceStored.length !== 0 && !hasConfirmation && steps.length > 0 && operatorStep === 'nin'){
      dispatch(setEventsFilters({
        property: 'registeredChoice',
        operator: 'eq',
        value: null
      }));
    }

    const newFilters = [];

    // Add regular steps filter
    if (otherSteps.length > 0) {
      newFilters.push({
        property: 'step',
        operator: operatorStep,
        value: otherSteps
      });
    }

    // Add printed filter only if badges imprimés is selected
    if (hasPrinted) {
      newFilters.push({
        property: 'printed',
        operator: operatorPrinted,
        value: 1
      });
    }

    // Add confirmation filter only if confirmation is explicitly selected
    if (hasConfirmation) {
      newFilters.push({
        property: 'registeredChoice',
        operator: operatorStep === 'in' ? 'neq' : 'eq',
        value: ''
      });
    }

    // Apply new filters while preserving opposite operator filters
    [...newFilters, ...oppositeFilters].forEach(filter => {
      if (filter.value !== null) {
        dispatch(setEventsFilters(filter));
      }
    });

  };

  return {
    handleEventChange: (eventId) => dispatch(setEventsFilters({ property: 'event.id', operator: 'eq', value: eventId })),
    handleStepsInChange: (steps, eventFilters) => handleStepsChange(dispatch, steps, 'in', 'eq', 'neq'),
    handleStepsNinChange: (steps, eventFilters) => handleStepsChange(dispatch, steps, 'nin', 'neq', 'eq'),
    fetchThemes: (filter) => dispatch(fetchTemplates(filter)),
    fetchEventFormats: (filter) => dispatch(fetchEventFormat(filter)),
    fetchApprovalTeams: () => dispatch(fetchApprovalTeams()),
    fetchTextModels: (lng) => dispatch(fetchTextModels(lng)),
    saveCampaignAutomation: (data) => dispatch(saveCampaignAutomation(data)),
    deleteCampaignAutomation: (campaignAutomationId) => dispatch(deleteAutomationCampaign(campaignAutomationId)),
    setEventsFilters: (data) => dispatch(setEventsFilters(data)),
    fetchAutomationCampaignConfigs: (pageSize, paginationPage) => dispatch(fetchAutomationCampaignConfigs(pageSize, paginationPage)),
    setTTPDialogCustomData: (data) => dispatch(setTTPDialogCustomData(data)),
    setAutomationPaginationPage : (pageNumber) => dispatch(setAutomationPaginationPage(pageNumber))
  }
})
export default class EmailAutomationCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSimple: false,
      object: '',
      name: '',
      lang: '',
      textModel: '',
      theme: '',
      hideRegisterButton: true,
      automation: null,
      showLoader: true,
      previewIsOpen: false,
      isThemeSet: false,
      selectedTheme: '',
      showMainBlock: false,
      currentTab: 'Configuration',
      expectedDateNumber: 1,
      hour: '00:00',
      isPresential: false,
      isHybrid: false,
      hasDocuments: false,
      hasReplay: false,
      isVirtual: false,
      hasApproval: false,
      showCompleteCampaign: false,
      expectedDate: '',
      classifications: [],
      isMain: 1,
      approvalTeams: null,
      completions: [],
      errors: {},
      automations: [],
      showAddCampaignAutomation: false,
      touchedFields: {}
    };
    this.handleSelectedThemes = this.handleSelectedThemes.bind(this);
    this.handleSelectedThemesType = this.handleSelectedThemesType.bind(this);
    this.handleObjectChangeInput = this.handleObjectChangeInput.bind(this);
    this.handleNameChangeInput = this.handleNameChangeInput.bind(this);
    this.handleTextModelChange = this.handleTextModelChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.saveAutomation = this.saveAutomation.bind(this);
    this.removeAutomation = this.removeAutomation.bind(this);
    this.handleInputNumberChange = this.handleInputNumberChange.bind(this);
    this.handleHourChange = this.handleHourChange.bind(this);
    this.handlePresentialChange = this.handlePresentialChange.bind(this);
    this.handleHybridChange = this.handleHybridChange.bind(this);
    this.handleVirtualChange = this.handleVirtualChange.bind(this);
    this.handleHasDocumentsChange = this.handleHasDocumentsChange.bind(this);
    this.handleHasReplayChange = this.handleHasReplayChange.bind(this);
    this.handleHasApproval = this.handleHasApproval.bind(this);
    this.handleDeleteCompleteLine = this.handleDeleteCompleteLine.bind(this);
    this.handleAddCompleteLine = this.handleAddCompleteLine.bind(this);
    this.handleCompleteLineChange = this.handleCompleteLineChange.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleAddAutomationBlock = this.handleAddAutomationBlock.bind(this);
    this.handleAutomationChange = this.handleAutomationChange.bind(this);
    this.handleDeleteAutomationBlock = this.handleDeleteAutomationBlock.bind(this);
  }

  componentDidMount() {
    let filter = {};
    let isSimple = this.props.automation ? this.props.automation.textModel : this.state.isSimple;
    filter.type =  isSimple ? [TEMPLATE_TYPES['SIMPLE'], TEMPLATE_TYPES['WRAPPER']] : TEMPLATE_TYPES['DRAG_AND_DROP'];
    filter.forAutomation = true;
    filter.allThemes = true;
    this.props.fetchThemes(filter);
    this.props.fetchEventFormats();
    if (this.props.teams.length === 0){
      this.props.fetchApprovalTeams();
    }
    const { emailAutomationCampaignConfigs } = this.props.automation || {};

    if (!emailAutomationCampaignConfigs) return;

    // First, let's prepare our state update
    const stateUpdate = {
      automations: []
    };

    // Process all configurations at once
    emailAutomationCampaignConfigs.forEach(automation => {
      if (!automation.isMain) {
        // Handle non-main automations
        const automationWithComplete = {
          ...automation,
          showCompleteCampaign: automation.completions && automation.completions.length > 0
        };
        stateUpdate.automations.push(automationWithComplete);
      } else {
        // Handle main automation
        Object.assign(stateUpdate, {
          expectedDateNumber: automation.expectedDateNumber,
          hour: automation.hour,
          isPresential: automation.isPresential,
          isHybrid: automation.isHybrid,
          isVirtual: automation.isVirtual,
          hasDocuments: automation.hasDocuments,
          hasReplay: automation.hasReplay,
          hasApproval: automation.hasApproval,
          expectedDate: automation.expectedDate,
          classifications: automation.classifications,
          approvalTeams: automation.approvalTeams,
          showCompleteCampaign: automation?.completions?.length > 0,
          completions: automation?.completions?.length > 0 ? automation.completions : [],
          showMainBlock: true,
        });
      }
    });

    // Set all state updates at once
    this.setState(stateUpdate);
    }

  componentDidUpdate(prevProps, prevState) {
    const { eventFilters, automation, themes } = this.props;
    const {
      object,
      name,
      lang,
      textModel,
      theme,
      isSimple,
      selectedTheme
    } = this.state;
    const includedStatus = eventFilters?.filter(condition => condition.operator === 'in' || condition.operator === 'neq' && condition.value !== null);
    const excludedStatus = eventFilters?.filter(condition => condition.operator === 'nin' || condition.operator === 'eq' && condition.value !== null);

    let shouldHideRegisterButton = true;

    // Check if all required fields are filled
    const hasRequiredFields = name !== '' && lang !== '' && theme !== '';
    const hasValidObject = selectedTheme?.themeObject || object !== '';
    const hasValidTextModel = !isSimple || (isSimple && textModel !== '');
    const hasValidFilters = includedStatus.length > 0 || excludedStatus.length > 0;

    // Show register button if all conditions are met
    if (hasRequiredFields && hasValidObject && hasValidTextModel && hasValidFilters) {
      shouldHideRegisterButton = false;
    } else {
      shouldHideRegisterButton = true;
    }

    if (this.state.hideRegisterButton !== shouldHideRegisterButton) {
      this.setState({ ...this.state, hideRegisterButton: shouldHideRegisterButton });
    }
    if(prevState.isSimple !== isSimple) {
      let filter = {};
      filter.type = isSimple ? [TEMPLATE_TYPES['SIMPLE'], TEMPLATE_TYPES['WRAPPER']] : TEMPLATE_TYPES['DRAG_AND_DROP'];
      filter.allThemes = true;
      filter.forAutomation = true;
      this.setState({showLoader: false})
      this.props.fetchThemes(filter);
      if (isSimple){
        this.props.fetchTextModels();
      }
    }

    if (prevState.automation !== automation){
      this.setState({...this.state, automation: automation, theme: automation.theme ? automation.theme?.id : this.state.theme,
        name: automation.name, object: automation.campaignObject, lang: automation.language, textModel: automation.textModel ? automation.textModel.id : this.state.textModel,
        isSimple: automation.textModel ? true : this.state.isSimple});
    }

  }

  handleAddAutomationBlock = () => {
    const { automations } = this.state;
    const newAutomation = {
      id: Date.now(),
      isPresential: false,
      isHybrid: false,
      isVirtual: false,
      hasDocuments: false,
      hasReplay: false,
      hasApproval: false,
      expectedDateNumber: '1',
      approvalTeams: null,
      hour: '00:00',
      expectedDate: '',
      classifications: [],
      showCompleteCampaign: false,
      completions: [],
    };

    this.setState(prevState => ({
      automations: [...prevState.automations, newAutomation]
    }));
  };


  validateField = async (field) => {
    const {
      classifications,
      expectedDate,
      approvalTeams,
      hasApproval,
      automations,
      completions
    } = this.state;
    const errors = {...this.state.errors};
    const touchedFields = {...this.state.touchedFields};
    // Mark the field as touched
    touchedFields[field] = true;

    // Validate all fields
    const newErrors = {};

    if (classifications?.length === 0) {
      newErrors.classifications = 'Select Format is required.';
    }

    if (!expectedDate) {
      newErrors.expectedDate = 'Select expected Date Status is required.';
    }

    if (hasApproval && !approvalTeams) {
      newErrors.approvalTeams = 'Select approval teams is required.';
    }

    completions.forEach((completion) => {
      if (!completion.expectedDate) {
        newErrors[`completion_${completion.id}_expectedDate`] = 'Select expected Date Status is required.';
      }
    });

    automations.forEach((automation) => {
      if (!automation.classifications.length) {
        newErrors[`automation_${automation.id}_classifications`] = 'Select Format is required for automation.';
      }
      if (!automation.expectedDate) {
        newErrors[`automation_${automation.id}_expectedDate`] = 'Select expected Date Status is required for automation.';
      }
      if (automation.hasApproval && !automation.approvalTeams) {
        newErrors[`automation_${automation.id}_approvalTeams`] = 'Select approval teams is required for automation.';
      }
      automation.completions?.forEach((completion) => {
        if (!completion.expectedDate) {
          newErrors[`automation_${automation.id}_completion_${completion.id}_expectedDate`] = 'Select expected Date Status is required for automation completion.';
        }
      });
    });

    // Update the error state for all fields
    Object.keys(newErrors).forEach((key) => {
      errors[key] = newErrors[key];
    });

    // Remove errors for fields that are no longer invalid
    Object.keys(errors).forEach((key) => {
      if (!newErrors[key]) {
        delete errors[key];
      }
    });

    await this.setState({errors, touchedFields});
    return errors;
  };
  renderErrorMessage = (field) => {
    const { errors, touchedFields } = this.state;
    if (touchedFields[field] && errors[field]) {
      return <span style={{ color: 'red', display: 'block', margin: '5px' }}>{errors[field]}</span>;
    }
    return null;
  };

  handleDeleteAutomationBlock = (automationId) => {
    this.setState(prevState => {
      const updatedAutomations = prevState.automations?.filter(automation => automation.id !== automationId);
      const updatedErrors = { ...prevState.errors };

      // Remove errors associated with the automation block
      delete updatedErrors[`automation_${automationId}_classifications`];
      delete updatedErrors[`automation_${automationId}_expectedDate`];
      delete updatedErrors[`automation_${automationId}_approvalTeams`];

      // Remove errors associated with the completions of the automation block
      prevState.automations
        ?.find(automation => automation.id === automationId)
        ?.completions?.forEach(completion => {
        delete updatedErrors[`automation_${automationId}_completion_${completion.id}_expectedDate`];
        delete updatedErrors[`automation_${automationId}_completion_${completion.id}_approvalTeams`];
      });

      return {
        automations: updatedAutomations,
        errors: updatedErrors
      };
    });
  };

  handleAutomationChange = (automationId, field, value) => {
    this.setState(prevState => ({
      automations: prevState.automations.map(automation => {
        if (automation.id === automationId) {
          const updatedAutomation = { ...automation, [field]: value };
          if (field === 'hasApproval' && !value) {
            updatedAutomation.approvalTeams = null;
          }
          return updatedAutomation;
        }
        return automation;
      })
    }));

    if (field === 'expectedDate' || field === 'approvalTeams' || field === 'classifications') {
      this.validateField(`automation_${automationId}_${field}`);
    }
  };

  validateAllFields = async () => {
    const { showMainBlock, completions, automations } = this.state;

    if (!showMainBlock) {
      return;
    }

    const fieldsToValidate = [
      'classifications',
      'expectedDate',
      'approvalTeams',
      ...(completions || []).map(completion => `completion_${completion.id}_expectedDate`),
      ...(completions || []).map(completion => `completion_${completion.id}_approvalTeams`),
      ...(automations || []).flatMap(automation => [
        `automation_${automation.id}_classifications`,
        `automation_${automation.id}_expectedDate`,
        `automation_${automation.id}_approvalTeams`,
        ...(automation.completions || []).map(completion => `automation_${automation.id}_completion_${completion.id}_expectedDate`),
        ...(automation.completions || []).map(completion => `automation_${automation.id}_completion_${completion.id}_approvalTeams`)
      ])
    ];

    for (const field of fieldsToValidate) {
      await this.validateField(field);
    }
  };

  handleTabChange = async (tab) => {
    if (tab === 'Configuration') {
      await this.validateAllFields();
    }
    const {errors} = this.state;
    if (Object.keys(errors).length === 0) {
      this.setState({currentTab: tab});
    } else {
      this.props.setTTPDialogCustomData({
        message: _("Please correct the errors before switching tabs."),
        proceedBtnLabel: _("OK"),
        title: "Validation Errors",
        type: DIALOG_TYPES["ALERT"],
        svgIcon: SVG_INFO_ICON,
      });
    }
  };

  handlePresentialChange = () => {
    this.setState({ isPresential: !this.state.isPresential });
  };

  handleHybridChange = () => {
    this.setState({ isHybrid: !this.state.isHybrid });
  };

  handleVirtualChange = () => {
    this.setState({ isVirtual: !this.state.isVirtual });
  };

  handleHasDocumentsChange = () => {
    this.setState({ hasDocuments: !this.state.hasDocuments });
  };

  handleHasReplayChange = () => {
    this.setState({ hasReplay: !this.state.hasReplay });
  };

  handleHasApproval = () => {
    this.setState(prevState => ({
      hasApproval: !prevState.hasApproval,
      approvalTeams: prevState.hasApproval ? null : prevState.approvalTeams
    }));
  };

  handleShowMainBlock = () => {
    this.setState(prevState => {
      const updatedErrors = { ...prevState.errors };

      if (prevState.showMainBlock) {
        // Remove errors associated with the main block
        delete updatedErrors['classifications'];
        delete updatedErrors['expectedDate'];
        delete updatedErrors['approvalTeams'];

        // Remove errors associated with the completions of the main block
        prevState.completions.forEach(completion => {
          delete updatedErrors[`completion_${completion.id}_expectedDate`];
          delete updatedErrors[`completion_${completion.id}_approvalTeams`];
        });
      }

      return {
        showMainBlock: !prevState.showMainBlock,
        errors: updatedErrors
      };
    });
  };

  handleSelectedThemesType() {
    this.setState({showLoader: false, isThemeSet: false})
    this.setState({...this.state, isSimple: !this.state.isSimple, theme: '', textModel: ''});
  }

  handleSelectedThemes(theme) {
    const { selectedTheme, object } = this.state;
    const updatedTheme = this.props.themes?.filter(item => theme == item.id);
    this.setState({...this.state, theme: theme, isThemeSet: true, object: updatedTheme && updatedTheme[0].themeObject ? updatedTheme[0].themeObject : '',
      selectedTheme: updatedTheme ? updatedTheme[0] : selectedTheme});
  }

  handleObjectChangeInput(object) {
    this.setState({...this.state, object: object});
  }

  handleNameChangeInput(name) {
    this.setState({...this.state, name: name});
  }

  handleTextModelChange(textModel) {
    this.setState({...this.state, textModel: textModel});
  }

  handleLanguageChange(language) {
    this.setState({...this.state, lang: language});
  }

  handleCancel() {
    this.setState( {
        isSimple: false,
        object: '',
        name: '',
        lang: '',
        textModel: '',
        theme: '',
        hideRegisterButton: true,
        automation: null,
        showLoader: true,
        previewIsOpen: false
      }
    );
    this.props.handleHideCreateAutomation(null);
  };

  handleInputNumberChange = (e) => {
    const newValue = parseInt(e.target.value) || 1;
    this.setState({
      expectedDateNumber: Math.max(1, Math.min(60, newValue))
    });
  };

  saveAutomation = () => {
    const { theme, textModel, name, object, lang, automation, showMainBlock } = this.state;
    const mainAutomation = {isPresential: this.state.isPresential, isHybrid: this.state.isHybrid, isVirtual: this.state.isVirtual,
      hasDocuments: this.state.hasDocuments, hasReplay: this.state.hasReplay, hasApproval: this.state.hasApproval,
      expectedDateNumber: this.state.expectedDateNumber, approvalTeams: this.state.approvalTeams, hour: this.state.hour,
      expectedDate: this.state.expectedDate, classifications: this.state.classifications, completions: this.state.completions, isMain: 1};
    let automationsToSave = [...this.state.automations, mainAutomation];
    automationsToSave = automationsToSave.map(automation => {
      return {
        ...automation,
        classifications: automation.classifications.map(entityId => ({
          eventClassification: parseInt(entityId)
        }))
      };
    });
    const data = { theme, textModel, name, campaignObject: object, language: lang, id: automation ? automation.id : null, emailAutomationCampaignConfigs: showMainBlock ? automationsToSave : [] };
    this.props.saveCampaignAutomation(data).then(res => {
      onSuccess(res);
      this.props.setAutomationPaginationPage(1);
      this.props.fetchAutomationCampaignConfigs(this.props.automationPageSize, 1);
      this.props.handleHideCreateAutomation(null);
      this.setState( {
          isSimple: false,
          object: '',
          name: '',
          lang: '',
          textModel: '',
          theme: '',
          hideRegisterButton: true,
          automation: null,
          showLoader: true,
          currentTab: 'Configuration',
          expectedDateNumber: 1,
          hour: '00:00',
          isPresential: false,
          isHybrid: false,
          hasDocuments: false,
          hasReplay: false,
          isVirtual: false,
          hasApproval: false,
          showCompleteCampaign: false,
          expectedDate: '',
          classifications: [],
          isMain: 1,
          completions: [],
          automations: [],
          showAddCampaignAutomation: false,
        }
      );
    }, err => onError(err));
  }

  removeAutomation = () => {
    const { automation } = this.state;
    this.props.deleteCampaignAutomation(automation.id).then(res => {
      onSuccess(res);
      this.props.setAutomationPaginationPage(1);
      this.props.fetchAutomationCampaignConfigs(this.props.automationPageSize, 1);
      this.props.handleHideCreateAutomation(null);
      this.setState( {
          isSimple: false,
          object: '',
          name: '',
          lang: '',
          textModel: '',
          theme: '',
          hideRegisterButton: true,
          automation: null,
          showLoader: true,
        }
      );
    }, err => onError(err));
  }

  deleteAutomation = () => {
    this.props.setTTPDialogCustomData({
      approvedAction: () => this.removeAutomation(),
      message: _("deleteCampaignAutomationConfirm"),
      proceedBtnLabel: _("yesDelete"),
      abortBtnLabel: _("no"),
      title: "Confirmation of deletion",
      type: DIALOG_TYPES["ALERT"],
      svgIcon: SVG_DELETE_ICON,
    });
  }

  handleHourChange = (e) => {
    this.setState({ hour: e.target.value });
  };

  handleSituationChange = (value) => {
    const errors = this.validateField('expectedDate');
    this.setState({ expectedDate: value, errors: errors });
  };

  handleApprovalTeamsChange = (value) => {
    const errors = this.validateField('approvalTeams');
    this.setState({ approvalTeams: value, errors: errors });
  };

  handleFormatChange = (e) => {
    const errors = this.validateField('classifications');
    this.setState({ classifications: e, errors: errors });
  };

  handleAddCompleteLine = (automation = null) => {
    if (automation){
      const newLine = {
        id: Date.now(),
        expectedDateNumber: '1',
        approvalTeams: null,
        hour: '00:00',
        hasApproval: false,
        expectedDate: ''
      };

      const updatesLines = [...automation.completions, newLine];

      this.setState(prevState => ({
        automations: prevState.automations.map(automa =>
          automa.id === automation.id ? { ...automa, ['completions']: updatesLines } : automa
        )
      }));
    } else {
      const newLine = {
        id: Date.now(),
        expectedDateNumber: '1',
        approvalTeams: null,
        hour: '00:00',
        hasApproval: false,
        expectedDate: ''
      };

      this.setState(prevState => ({
        completions: [...prevState.completions, newLine]
      }));
    }
  };

  handleDeleteCompleteLine = (lineId, automation = null) => {
    if (automation){
      this.setState(prevState => {
        const updatedLines = automation.completions.filter(line => line.id !== lineId);
        const updatedErrors = { ...prevState.errors };

        // Remove errors associated with the completion
        delete updatedErrors[`automation_${automation.id}_completion_${lineId}_expectedDate`];
        delete updatedErrors[`automation_${automation.id}_completion_${lineId}_approvalTeams`];

        if (updatedLines.length === 0) {
          automation.completions = [];
          automation.showCompleteCampaign = false;
          return {
            automations: prevState.automations.map(automa =>
              automa.id === automation.id ? automation : automa
            ),
            errors: updatedErrors
          };
        }
        automation.completions = updatedLines;

        return {
          automations: prevState.automations.map(automa =>
            automa.id === automation.id ? automation : automa
          ),
          errors: updatedErrors
        };
      });
    } else {
      this.setState(prevState => {
        const updatedLines = prevState.completions.filter(line => line.id !== lineId);

        const updatedErrors = { ...prevState.errors };

        // Remove errors associated with the completion
        delete updatedErrors[`completion_${lineId}_expectedDate`];
        delete updatedErrors[`completion_${lineId}_approvalTeams`];

        // If it's the last line, hide the complete campaign section
        if (updatedLines.length === 0) {
          return {
            showCompleteCampaign: false,
            completions: [],
            errors: updatedErrors
          };
        }

        return {
          completions: updatedLines,
          errors: updatedErrors
        };
      });
    }
  };

  handleCompleteLineChange = (lineId, field, value, automation = null) => {
    if (automation){
      const updatedAutomation = {
        ...automation,
        completions: automation.completions.map(line =>
          line.id === lineId ? { ...line, [field]: value } : line
        )
      };

      // Then update the state, replacing the old automation with the updated one
      this.setState(prevState => ({
        automations: prevState.automations.map(automa =>
          automa.id === automation.id ? updatedAutomation : automa
        )
      }));

      if (field === 'expectedDate' || field === 'approvalTeams') {
        this.validateField(`automation_${automation.id}_completion_${lineId}_${field}`);
      }

    } else {
      this.setState(prevState => ({
        completions: prevState.completions.map(line =>
          line.id === lineId ? { ...line, [field]: value } : line
        )
      }));

      if (field === 'expectedDate' || field === 'approvalTeams') {
        this.validateField(`completion_${lineId}_${field}`);
      }

    }
  };

  handleAddCompleteCampaign = (automation = null) => {
    if (automation){
      this.setState(prevState => ({
        automations: prevState.automations.map(automa =>
          automa.id === automation.id ? { ...automa, ['showCompleteCampaign']: true,
            ['completions']: [{
              id: Date.now(),
              expectedDateNumber: '1',
              hour: '00:00',
              hasApproval: false,
              expectedDate: ''
            }]
          } : automa
        )
      }));
    } else {
      this.setState({ showCompleteCampaign: true,
        completions: [{
          id: Date.now(),
          expectedDateNumber: '1',
          hour: '00:00',
          hasApproval: false,
          expectedDate: ''
        }]
      });
    }
  };

  renderAutomationTab = () => {
    const { eventFormat, language, teams } = this.props;
    const { isPresential, hasApproval, isHybrid, hasReplay, hasDocuments, isVirtual, expectedDateNumber, hour,
      showCompleteCampaign, completions, expectedDate, automations, classifications, approvalTeams, showMainBlock } = this.state;

    let eventFormats = eventFormat?.map(format => {
      const getEventName = () => {
        if (language !== 'All' && format[`name${language}`]) {
          return format[`name${language}`];
        }
        // Fallback order: Fr -> Nl -> En
        return format['nameFr'] || format['nameNl'] || format['nameEn'] || 'Unnamed Format';
      };

      const formatName = getEventName();

      return {
        label: formatName,
        id: format.id.toString()
      };
    });

    return (
      <div>
        <div>
          <div className="row minus-add__actions" style={{marginTop: '2rem', justifyContent: 'unset', maxWidth: showMainBlock && automations.length === 0 ? '22%' : '75%'}}>
            {!showMainBlock &&
              <div className="row medium-11 title">
                <h3>{_("add automation")}</h3>
                <p>{_("by clicking on the button +")}</p>
              </div>
            }
            {showMainBlock && automations.length === 0 &&
              <div className="row medium-11 title">
                <h3>{_("delete automation")}</h3>
                <p>{_("by clicking on the button -")}</p>
              </div>
            }
            <div className="minus-add__actions-container">
              {showMainBlock && automations.length === 0 &&
                <span className={"minus"} onClick={this.handleShowMainBlock}>{SVG_MINUS}</span>
              }
              {!showMainBlock &&
                <span className="add" onClick={this.handleShowMainBlock}>{SVG_PLUS}</span>
              }
            </div>
          </div>
        </div>
        {showMainBlock &&
          <div>
            <div className="recipients-selection columns small-12 columns-padding">
              <h2
                className="recipients-selection__title">{_('Event selection')}</h2>
              <div className="recipients-selection__content">
                <div className="columns small-6 columns-padding">
                  <div className="form-control">
                    <h1 className="param__title"
                        style={{margin: '1rem 0 0.34rem'}}>{_('Format')}</h1>
                    <TTPSelect
                      values={classifications}
                      placeholder={_('Select format')}
                      options={eventFormats}
                      onChange={this.handleFormatChange}
                      onBlur={() => this.validateField('classifications')}
                      disabled={[]}
                    />
                    {this.renderErrorMessage('classifications')}
                  </div>
                </div>
                  <div className="columns small-3 columns-padding">
                    <span style={{display: "flex"}}>
                          <input
                            type="checkbox"
                            style={{
                              display: 'flex',
                              height: '16px',
                              width: '17px',
                            }}
                            id={'1'}
                            checked={isPresential}
                            onChange={this.handlePresentialChange}
                          />
                            <span
                              style={{margin: '-1px 0 0 8px'}}>{_('is Presential')}</span>
                    </span>
                    <span style={{display: "flex"}}>
                          <input
                            type="checkbox"
                            style={{
                              display: 'flex',
                              height: '16px',
                              width: '17px',
                              zIndex: '3'
                            }}
                            id={'1'}
                            checked={isVirtual}
                            onChange={this.handleVirtualChange}
                          />
                            <span
                              style={{margin: '-1px 0 0 8px'}}>{_('is Virtual')}</span>
                    </span>
                    <span style={{display: "flex"}}>
                          <input
                            type="checkbox"
                            style={{
                              display: 'flex',
                              height: '16px',
                              width: '17px',
                              zIndex: '3'
                            }}
                            id={'1'}
                            checked={isHybrid}
                            onChange={this.handleHybridChange}
                          />
                            <span
                              style={{margin: '-1px 0 0 8px'}}>{_('is Hybrid')}</span>
                    </span>
                  </div>
                  <div className="columns small-3 columns-padding">
                    <span style={{display: "flex"}}>
                          <input
                            type="checkbox"
                            style={{
                              display: 'flex',
                              height: '16px',
                              width: '17px',
                              zIndex: '3'
                            }}
                            id={'1'}
                            checked={hasReplay}
                            onChange={this.handleHasReplayChange}
                          />
                            <span
                              style={{margin: '-1px 0 0 8px'}}>{_('has replay')}</span>
                    </span>
                    <span style={{display: "flex"}}>
                          <input
                            type="checkbox"
                            style={{
                              display: 'flex',
                              height: '16px',
                              width: '17px',
                              zIndex: '3'
                            }}
                            id={'1'}
                            checked={hasDocuments}
                            onChange={this.handleHasDocumentsChange}
                          />
                            <span
                              style={{margin: '-1px 0 0 8px'}}>{_('has documents')}</span>
                    </span>
                  </div>

                </div>
              </div>
              <div className="columns small-2 columns-padding"></div>
              <div className="recipients-selection columns small-11 columns-padding">
                <h2
                  className="recipients-selection__title">{_('When')}</h2>
                <div className="recipients-selection__content">
                  <div className="columns small-1 columns-padding">
                    <div className=" form-control flex items-center">
                      <div className="relative w-24">
                        <h1 className="param__title"
                            style={{margin: '1rem 0 0.34rem'}}></h1>
                        <input
                          type="number"
                          value={expectedDateNumber}
                          onChange={this.handleInputNumberChange}
                          min="1"
                          max="60"
                          className="w-full px-3 py-2 border rounded text-center"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="columns small-3 columns-padding">
                    <div className="form-control">
                      <h1 className="param__title"
                          style={{margin: '1rem 0 0.34rem'}}></h1>
                      <TTPSelect
                        simple={true}
                        values={expectedDate}
                        placeholder={_('Select expected Date Status')}
                        options={AUTOMATION_TIME_STATUS}
                        onChange={this.handleSituationChange}
                        onBlur={() => this.validateField('expectedDate')}
                        disabled={[]}
                      />
                      {this.renderErrorMessage('expectedDate')}
                    </div>
                  </div>
                  {!['Hours before the event', 'Hours after the event'].includes(expectedDate) &&
                    <>
                      <div className="columns small-1 columns-padding">
                        <h1 className="param__title"
                            style={{margin: '1.5rem 0 0.34rem'}}></h1>
                        <span style={{marginLeft: '18px'}}>At</span>
                      </div>
                      <div className="columns small-2 columns-padding">
                        <h1 className="param__title"
                            style={{margin: '1rem 0 0.34rem'}}></h1>
                        <input
                          type="time"
                          value={hour}
                          onChange={this.handleHourChange}
                          className="w-20 px-2 py-1 border rounded"
                        />
                      </div>
                    </>
                  }
                  <div className="columns small-2 columns-padding">
                    <h1 className="param__title"
                        style={{margin: '1.5rem 0 0.34rem'}}></h1>
                    <span style={{display: "flex", justifyContent: 'center'}}>
                          <input
                            type="checkbox"
                            style={{
                              display: 'flex',
                              height: '16px',
                              width: '17px',
                            }}
                            id={'1'}
                            checked={hasApproval}
                            onChange={this.handleHasApproval}
                          />
                            <span
                              style={{margin: '-1px 0 0 8px'}}>{_('has approval')}</span>
                    </span>
                  </div>
                  {hasApproval && (
                    <div className="columns small-3 columns-padding">
                      <h1 className="param__title"></h1>
                      <TTPSelect
                        simple={true}
                        values={approvalTeams}
                        placeholder={_('Select approval teams')}
                        options={teams.filter(team => team.members && team.members.length)}
                        onChange={this.handleApprovalTeamsChange}
                        onBlur={() => this.validateField('approvalTeams')}
                        cssClass={(!approvalTeams || !approvalTeams.length) && "empty-box"}
                        customOption={TeamOption}
                      />
                      {this.renderErrorMessage('approvalTeams')}
                    </div>
                  )}
                </div>
              </div>
              <div className="columns small-2 columns-padding"></div>
              {!showCompleteCampaign && (
                <div className="text-center my-3">
                  <button
                    onClick={() => this.handleAddCompleteCampaign()}
                    style={{cursor: 'pointer'}}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    <i className={`icon-left info icon-plus`}/>
                    {_("Add Complete Campaign")}
                  </button>
                </div>
              )}
              {showCompleteCampaign && (
                <>
                  <div className="columns small-2 columns-padding"></div>
                  <div
                    className="recipients-selection columns small-8 columns-padding">
                    <h2
                      className="recipients-selection__title">{_('Complete campaign')}</h2>
                    {completions.map((line, index) => (
                      <div key={line.id} className="recipients-selection__content">
                        <div className="columns small-1 columns-padding">
                          <div className="form-control flex items-center">
                            <div className="relative w-24">
                              <h1 className="param__title"
                                  style={{margin: '1rem 0 0.34rem'}}></h1>
                              <input
                                type="number"
                                value={line.expectedDateNumber}
                                onChange={(e) => this.handleCompleteLineChange(line.id, 'expectedDateNumber', e.target.value)}
                                min="1"
                                max="60"
                                className="w-full px-3 py-2 border rounded text-center"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="columns small-4 columns-padding">
                          <div className="form-control">
                            <h1 className="param__title"
                                style={{margin: '1rem 0 0.34rem'}}></h1>
                            <TTPSelect
                              values={line.expectedDate}
                              placeholder={_('Select expected Date Status')}
                              options={AUTOMATION_TIME_STATUS}
                              onChange={(value) => this.handleCompleteLineChange(line.id, 'expectedDate', value)}
                              onBlur={() => this.validateField(`completion_${line.id}_expectedDate`)}
                              disabled={[]}
                              simple={true}
                            />
                            {this.renderErrorMessage(`completion_${line.id}_expectedDate`)}
                          </div>
                        </div>
                        {!['Hours before the event', 'Hours after the event'].includes(line.expectedDate) &&
                          <>
                            <div className="columns small-1 columns-padding">
                              <h1 className="param__title"
                                  style={{margin: '1.5rem 0 0.34rem'}}></h1>
                              <span style={{marginLeft: '18px'}}>At</span>
                            </div>
                            <div className="columns small-2 columns-padding">
                              <h1 className="param__title"
                                  style={{margin: '1rem 0 0.34rem'}}></h1>
                              <input
                                type="time"
                                value={line.hour}
                                onChange={(e) => this.handleCompleteLineChange(line.id, 'hour', e.target.value)}
                                className="w-20 px-2 py-1 border rounded"
                              />
                            </div>
                          </>
                        }
                        <div className="columns small-2 columns-padding">
                          <h1 className="param__title"
                              style={{margin: '1.5rem 0 0.34rem'}}></h1>
                          <span style={{display: "flex", justifyContent: 'center'}}>
                            <input
                              type="checkbox"
                              style={{
                                display: 'flex',
                                height: '16px',
                                width: '17px',
                              }}
                              checked={line.hasApproval}
                              onChange={(e) => this.handleCompleteLineChange(line.id, 'hasApproval', e.target.checked)}
                            />
                            <span
                              style={{margin: '-1px 0 0 8px'}}>{_('has approval')}</span>
                          </span>
                        </div>
                        <div className="columns small-1 columns-padding">
                          <h1 className="param__title"></h1>
                          <span style={{display: "flex", justifyContent: 'center'}}>
                              <span style={{
                                cursor: 'pointer',
                                color: 'red',
                                lineHeight: 3
                              }}
                                    onClick={() => this.handleDeleteCompleteLine(line.id)}>
                                      <i className={`icon-left danger icon-trash`}/>
                              </span>
                              <span
                                style={{
                                  cursor: 'pointer',
                                  color: 'black',
                                  lineHeight: 3,
                                  marginLeft: '10px'
                                }}
                                onClick={() => this.handleAddCompleteLine()}>
                                <i className={`icon-left info icon-plus`}/>
                              </span>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="columns small-2 columns-padding"></div>
                </>
              )}
              <div className="text-center my-3"
                   style={{border: 'solid 1px #101E2A', margin: '20px 20px'}}></div>
              {automations.length > 0 && automations.map((automation, index) => {
                return this.renderAutomationBlock(automation, eventFormats, teams);
              })}
              <div className="text-center my-3" style={{fontSize: '18px'}}>
                <button
                  onClick={this.handleAddAutomationBlock}
                  style={{cursor: 'pointer'}}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  <i className={`icon-left info icon-plus`}/>
                  {_('Add campaign automation')}
                </button>
              </div>
            </div>
        }
      </div>
    );
  };

  renderAutomationBlock = (automation, eventFormats, teams) => (
    <>
      <div className="columns small-12 columns-padding">
        <h1 className="param__title"></h1>
        <span style={{display: "flex", justifyContent: 'center'}}>
          <span style={{
            cursor: 'pointer',
            color: 'red',
            lineHeight: 3,
            fontSize: '15px'
          }}
                onClick={() => this.handleDeleteAutomationBlock(automation.id)}>
            <i className={`icon-left danger icon-trash`}/>
            {_("Delete automation block")}
          </span>
        </span>
      </div>
      <div key={automation.id} className="automation-block">
        <div className="recipients-selection columns small-12 columns-padding">
          <h2
            className="recipients-selection__title">{_('Event selection')}</h2>
          <div className="recipients-selection__content">
            <div className="columns small-6 columns-padding">
              <div className="form-control">
                <h1 className="param__title"
                    style={{margin: '1rem 0 0.34rem'}}>{_('Format')}</h1>
                <TTPSelect
                  values={automation?.classifications}
                  placeholder={_('Select format')}
                  options={eventFormats}
                  onChange={(e) => this.handleAutomationChange(automation.id, 'classifications', e)}
                  onBlur={() => this.validateField(`automation_${automation.id}_classifications`)}
                  disabled={[]}
                />
                {this.renderErrorMessage(`automation_${automation.id}_classifications`)}
              </div>
            </div>
            <div className="columns small-3 columns-padding">
                <span style={{display: "flex"}}>
                  <input
                    type="checkbox"
                    style={{display: 'flex', height: '16px', width: '17px'}}
                    checked={automation.isPresential}
                    onChange={(e) => this.handleAutomationChange(automation.id, 'isPresential', e.target.checked)}
                  />
                  <span
                    style={{margin: '-1px 0 0 8px'}}>{_('is Presential')}</span>
                </span>
              <span style={{display: "flex"}}>
                  <input
                    type="checkbox"
                    style={{display: 'flex', height: '16px', width: '17px'}}
                    checked={automation.isVirtual}
                    onChange={(e) => this.handleAutomationChange(automation.id, 'isVirtual', e.target.checked)}
                  />
                  <span
                    style={{margin: '-1px 0 0 8px'}}>{_('is Virtual')}</span>
                </span>
              <span style={{display: "flex"}}>
                  <input
                    type="checkbox"
                    style={{display: 'flex', height: '16px', width: '17px'}}
                    checked={automation.isHybrid}
                    onChange={(e) => this.handleAutomationChange(automation.id, 'isHybrid', e.target.checked)}
                  />
                  <span style={{margin: '-1px 0 0 8px'}}>{_('is Hybrid')}</span>
                </span>
            </div>
            <div className="columns small-3 columns-padding">
                <span style={{display: "flex"}}>
                  <input
                    type="checkbox"
                    style={{display: 'flex', height: '16px', width: '17px'}}
                    checked={automation.hasReplay}
                    onChange={(e) => this.handleAutomationChange(automation.id, 'hasReplay', e.target.checked)}
                  />
                  <span
                    style={{margin: '-1px 0 0 8px'}}>{_('has replay')}</span>
                </span>
              <span style={{display: "flex"}}>
                  <input
                    type="checkbox"
                    style={{display: 'flex', height: '16px', width: '17px'}}
                    checked={automation.hasDocuments}
                    onChange={(e) => this.handleAutomationChange(automation.id, 'hasDocuments', e.target.checked)}
                  />
                  <span
                    style={{margin: '-1px 0 0 8px'}}>{_('has documents')}</span>
                </span>
            </div>
          </div>
        </div>
        <div className="columns small-2 columns-padding"></div>
        <div className="recipients-selection columns small-11 columns-padding">
          <h2 className="recipients-selection__title">{_('When')}</h2>
          <div className="recipients-selection__content">
            <div className="columns small-1 columns-padding">
              <div className="form-control flex items-center">
                <div className="relative w-24">
                  <h1 className="param__title"
                      style={{margin: '1rem 0 0.34rem'}}></h1>
                  <input
                    type="number"
                    value={automation.expectedDateNumber}
                    onChange={(e) => this.handleAutomationChange(automation.id, 'expectedDateNumber', e.target.value)}
                    min="1"
                    max="60"
                    className="w-full px-3 py-2 border rounded text-center"
                  />
                </div>
              </div>
            </div>
            <div className="columns small-3 columns-padding">
              <div className="form-control">
                <h1 className="param__title"
                    style={{margin: '1rem 0 0.34rem'}}></h1>
                <TTPSelect
                  simple={true}
                  values={automation.expectedDate}
                  placeholder={_('Select expected Date Status')}
                  options={AUTOMATION_TIME_STATUS}
                  onChange={(value) => this.handleAutomationChange(automation.id, 'expectedDate', value)}
                  onBlur={() => this.validateField(`automation_${automation.id}_expectedDate`)}
                  disabled={[]}
                />
                {this.renderErrorMessage(`automation_${automation.id}_expectedDate`)}
              </div>
            </div>
            { !['Hours before the event', 'Hours after the event'].includes(automation.expectedDate) &&
              <>
                <div className="columns small-1 columns-padding">
                  <h1 className="param__title"
                      style={{margin: '1.5rem 0 0.34rem'}}></h1>
                  <span style={{marginLeft: '18px'}}>At</span>
                </div>
                <div className="columns small-2 columns-padding">
                  <h1 className="param__title"
                      style={{margin: '1rem 0 0.34rem'}}></h1>
                  <input
                    type="time"
                    value={automation.hour}
                    onChange={(e) => this.handleAutomationChange(automation.id, 'hour', e.target.value)}
                    className="w-20 px-2 py-1 border rounded"
                  />
                </div>
              </>
            }
            <div className="columns small-2 columns-padding">
              <h1 className="param__title"
                  style={{margin: '1.5rem 0 0.34rem'}}></h1>
              <span style={{display: "flex", justifyContent: 'center'}}>
                  <input
                    type="checkbox"
                    style={{display: 'flex', height: '16px', width: '17px'}}
                    checked={automation.hasApproval}
                    onChange={(e) => this.handleAutomationChange(automation.id, 'hasApproval', e.target.checked)}
                  />
                  <span
                    style={{margin: '-1px 0 0 8px'}}>{_('has approval')}</span>
                </span>
            </div>
            {automation.hasApproval && (
              <div className="columns small-3 columns-padding">
                <h1 className="param__title"></h1>
                <TTPSelect
                  simple={true}
                  values={automation.approvalTeams}
                  placeholder={_('Select approval teams')}
                  options={teams.filter(team => team.members && team.members.length)}
                  onChange={(e) => this.handleAutomationChange(automation.id, 'approvalTeams', e)}
                  onBlur={() => this.validateField(`automation_${automation.id}_approvalTeams`)}
                  cssClass={(!automation.approvalTeams || !automation.approvalTeams.length) && "empty-box"}
                  customOption={TeamOption}
                />
                {this.renderErrorMessage(`automation_${automation.id}_approvalTeams`)}
              </div>
            )}
          </div>
        </div>
        <div className="columns small-2 columns-padding"></div>
        {!automation.showCompleteCampaign && (
          <div className="text-center my-3">
            <button
              onClick={() => this.handleAddCompleteCampaign(automation)}
              style={{cursor: 'pointer'}}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              <i className={`icon-left info icon-plus`}/>
              {_('Add Complete Campaign')}
            </button>
          </div>
        )}
        {automation.showCompleteCampaign && (
          <>
            <div className="columns small-2 columns-padding"></div>
            <div
              className="recipients-selection columns small-8 columns-padding">
              <h2
                className="recipients-selection__title">{_('Complete campaign')}</h2>
              {automation.completions.map((line, index) => (
                <div key={line.id} className="recipients-selection__content">
                  <div className="columns small-1 columns-padding">
                    <div className="form-control flex items-center">
                      <div className="relative w-24">
                        <h1 className="param__title"
                            style={{margin: '1rem 0 0.34rem'}}></h1>
                        <input
                          type="number"
                          value={line.expectedDateNumber}
                          onChange={(e) => this.handleCompleteLineChange(line.id, 'expectedDateNumber', e.target.value, automation)}
                          min="1"
                          max="60"
                          className="w-full px-3 py-2 border rounded text-center"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="columns small-4 columns-padding">
                    <div className="form-control">
                      <h1 className="param__title"
                          style={{margin: '1rem 0 0.34rem'}}></h1>
                      <TTPSelect
                        values={line.expectedDate}
                        placeholder={_('Select expected Date Status')}
                        options={AUTOMATION_TIME_STATUS}
                        onChange={(value) => this.handleCompleteLineChange(line.id, 'expectedDate', value, automation)}
                        onBlur={() => this.validateField(`completion_${line.id}_expectedDate`)}
                        disabled={[]}
                        simple={true}
                      />
                      {this.renderErrorMessage(`automation_${automation.id}_completion_${line.id}_expectedDate`)}
                    </div>
                  </div>
                  { !['Hours before the event', 'Hours after the event'].includes(line.expectedDate) &&
                    <>
                      <div className="columns small-1 columns-padding">
                        <h1 className="param__title"
                            style={{margin: '1.5rem 0 0.34rem'}}></h1>
                        <span style={{marginLeft: '18px'}}>At</span>
                      </div>
                      <div className="columns small-2 columns-padding">
                        <h1 className="param__title"
                            style={{margin: '1rem 0 0.34rem'}}></h1>
                        <input
                          type="time"
                          value={line.hour}
                          onChange={(e) => this.handleCompleteLineChange(line.id, 'hour', e.target.value, automation)}
                          className="w-20 px-2 py-1 border rounded"
                        />
                      </div>
                    </>
                  }
                  <div className="columns small-2 columns-padding">
                    <h1 className="param__title"
                        style={{margin: '1.5rem 0 0.34rem'}}></h1>
                    <span style={{display: "flex", justifyContent: 'center'}}>
                          <input
                            type="checkbox"
                            style={{
                              display: 'flex',
                              height: '16px',
                              width: '17px',
                            }}
                            checked={line.hasApproval}
                            onChange={(e) => this.handleCompleteLineChange(line.id, 'hasApproval', e.target.checked, automation)}
                          />
                          <span
                            style={{margin: '-1px 0 0 8px'}}>{_('has approval')}</span>
                        </span>
                  </div>
                  <div className="columns small-1 columns-padding">
                    <h1 className="param__title"></h1>
                    <span style={{display: "flex", justifyContent: 'center'}}>
                            <span style={{
                              cursor: 'pointer',
                              color: 'red',
                              lineHeight: 3
                            }}
                                  onClick={() => this.handleDeleteCompleteLine(line.id, automation)}>
                              <i className={`icon-left danger icon-trash`}/>
                            </span>
                            <span style={{
                              cursor: 'pointer',
                              color: 'black',
                              lineHeight: 3,
                              marginLeft: '10px'
                            }}
                                  onClick={() => this.handleAddCompleteLine(automation)}>
                              <i className={`icon-left info icon-plus`}/>
                            </span>
                        </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="columns small-2 columns-padding"></div>
          </>
        )}
        <div className="text-center my-3"
             style={{border: 'solid 1px #101E2A', margin: '20px 20px'}}></div>
      </div>
    </>
  );

  renderConfigurationTab = () => {
    const {
      eventFilters,
      handleStepsNinChange,
      handleStepsInChange, themes, textModels, automation, themesFetching, campaignAutomationsFetching
    } = this.props;
    const {
      object,
      isSimple,
      name,
      lang,
      textModel,
      theme,
      hideRegisterButton,
      showLoader,
      previewIsOpen,
      isThemeSet,
      selectedTheme
    } = this.state;
    if (themesFetching && showLoader || campaignAutomationsFetching) {
      return (<div style={{position: "relative", paddingBottom: '20rem'}}>
        <TTPLoader/>
      </div>);
    }
    let filterMapping = eventFilters.reduce((filters, filter) => {
      let key = `${filter.property}-${filter.operator}`;
      filters[key] = filter.value;
      return filters;
    }, {});
    const shouldOpenPreview = !isSimple && theme || isSimple && theme && textModel;

    let includedSteps = filterMapping['step-in'] || [];
    let excludedSteps = filterMapping['step-nin'] || [];
    let isIncludedPrinted = filterMapping['printed-eq'] === 1;
    let isExcludedPrinted = filterMapping['printed-neq'] === 1;
    let isIncludedConfirmation = filterMapping['registeredChoice-neq'] === '';
    let isExcludedConfirmation = filterMapping['registeredChoice-eq'] === '';
    if (isIncludedPrinted && !includedSteps.includes('105')) {
      includedSteps = [...includedSteps, '105'];
    }

    if (isExcludedPrinted && !excludedSteps.includes('105')) {
      excludedSteps = [...excludedSteps, '105'];
    }

    if (isIncludedConfirmation && !includedSteps.includes('5')) {
      includedSteps = [...includedSteps, '5'];
    }

    if (isExcludedConfirmation && !excludedSteps.includes('5')) {
      excludedSteps = [...excludedSteps, '5'];
    }

    let themeOptions = themes?.map(theme => {
      return {
        label: theme.name,
        id: theme.id
      };
    });

    let texteModelsOptions = textModels?.map(textModel => {
      return {
        label: textModel.object,
        id: textModel.id
      };
    });

    const languagesOptions = [{id: 'fr', label: _('fr')},{id: 'nl', label: _('nl')}, {id: 'all', label: _('all')}];

    return (
      <>
        {previewIsOpen &&
          <Viewer
            isOpen={previewIsOpen}
            onClose={() => this.setState({previewIsOpen: false})}
            url={`${TTP_API_URL}/mailing/web-version?themeId=${theme}&textModelId=${textModel}&access_token=${this.props.token}`}
          />
        }
        <div className="row">
          <div className="columns small-6 columns-padding">
            <div>
              <h1 className="param__title" style={{
                fontWeight: '600',
                fontSize: '22px',
                marginTop: '56px',
                marginLeft: '-15px'
              }}>{!automation ? _('eventCampaignTemplateCreate') : _('eventCampaignTemplateEdit')}</h1>
            </div>
          </div>
          <div className="columns small-6 columns-padding">
          </div>
        </div>
        <div className="row">
          <div className="columns small-6 columns-padding">
            <div className="sidebar__form-control">
              <h1 className="param__title">{_('automationCampaignName')}</h1>
              <TagsInput
                value={[]}
                onlyUnique={true}
                addOnPaste
                inputProps={{placeholder: _("automationCampaignName")}}
                inputValue={name}
                onChangeInput={this.handleNameChangeInput}
              />
            </div>
          </div>
          <div className="columns small-6 columns-padding">
            {(!(selectedTheme?.themeObject) && isThemeSet || automation && automation.campaignObject && !automation.theme?.themeObject) &&
              <div className="sidebar__form-control">
                <h1 className="param__title"
                    style={{margin: '1rem 0 0.34rem'}}>{_('campaignSubject')}</h1>
                <TagsInput
                  value={[]}
                  onlyUnique={true}
                  addOnPaste
                  inputProps={{placeholder: _("campaignSubject")}}
                  inputValue={object}
                  onChangeInput={this.handleObjectChangeInput}
                />
                <span
                  className={!object ? 'form-error is-visible' : 'form-error'} style={{marginLeft: '10px'}}>
                  {_("errorTheme")}
                </span>
              </div>
            }
          </div>
          <div className="columns small-5 columns-padding">
            <div className="form-control">
              <h1 className="param__title" style={{margin: '1rem 0 0.34rem'}}>{!isSimple ? _('theme') : _('model')}
                <span style={{float: "right", display: "flex"}}>
                  <input
                    type="checkbox"
                    style={{
                      display: 'flex',
                      height: '16px',
                      width: '17px',
                      zIndex: '3'
                    }}
                    id={'1'}
                    checked={isSimple}
                    onChange={this.handleSelectedThemesType}
                  />
                    <span
                      style={{margin: '-1px 0 0 -10px'}}>{_('simple')}</span>
                </span>
              </h1>
              <TTPSelect
                simple={true}
                notClearable={true}
                values={theme}
                placeholder={_('selectTheme')}
                options={themeOptions}
                onChange={this.handleSelectedThemes}
              />
            </div>
          </div>
          {isSimple &&
            <div className="columns small-5 columns-padding">
              <div className="form-control">
                <h1 className="param__title"
                    style={{margin: '1rem 0 0.34rem'}}>{_('contentForTheme')}</h1>
                <TTPSelect
                  simple={true}
                  notClearable={true}
                  values={textModel}
                  placeholder={_('select Content')}
                  options={texteModelsOptions}
                  onChange={this.handleTextModelChange}
                />
              </div>
            </div>
          }
          {shouldOpenPreview &&
            <div className="columns small-2 columns-padding"
                 style={{paddingTop: '3rem'}}>
              <button
                className="btn secondary side-page-header__button"
                onClick={() => {
                  this.setState({previewIsOpen: true})
                }}
              >
                <i className={`icon-left icon-eye`}/>
                <span>{_('preview')}</span>
              </button>
            </div>
          }
          {!isSimple &&
            <div className="columns small-5 columns-padding"/>
          }
          <div className="recipients-selection columns small-12 columns-padding">
            <h2
              className="recipients-selection__title">{_('recipientsSelection')}</h2>
            <div className="recipients-selection__content">
              <div className="columns small-6 columns-padding">
                <div className="form-control">
                  <h1 className="param__title"
                      style={{margin: '1rem 0 0.34rem'}}>{_('Included status')}</h1>
                  <TTPSelect
                    values={includedSteps}
                    placeholder={_('Select status')}
                    options={GUEST_STATUS}
                    onChange={handleStepsInChange}
                    disabled={excludedSteps}/>
                </div>
              </div>
              <div className="columns small-6 columns-padding">
                <div className="form-control">
                  <h1 className="param__title"
                      style={{margin: '1rem 0 0.34rem'}}>{_('Excluded status')}</h1>
                  <TTPSelect
                    values={excludedSteps}
                    placeholder={_('Exclude status')}
                    options={GUEST_STATUS}
                    onChange={handleStepsNinChange}
                    disabled={includedSteps}/>
                </div>
              </div>
              <div className="columns small-6 columns-padding">
                <div className="form-control">
                  <h1 className="param__title"
                      style={{margin: '1rem 0 0.34rem'}}>{_('language')}</h1>
                  <TTPSelect
                    simple={true}
                    notClearable={true}
                    values={lang}
                    placeholder={_('select language')}
                    options={languagesOptions}
                    onChange={this.handleLanguageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ttp-small-buttons"
             style={{margin: '1.5rem 0 5rem 0'}}>
          <button className="cancel"
                  onClick={this.handleCancel}>{_('cancel')}</button>
          <button className={`success`}
                  style={hideRegisterButton ? {
                    background: '#b7e6dd',
                    pointer: 'unset'
                  } : {}}
                  onClick={!hideRegisterButton ? this.saveAutomation : () => {
                  }}>{_('save')}</button>
          {automation &&
            <div style={{position: 'absolute', right: '24vh'}}>
              <span style={{cursor: 'pointer', color: 'red', lineHeight: 3}}
                    onClick={() => this.deleteAutomation()}>
                      <i className={`icon-left danger icon-trash`}/>{_('deleteTemplate')}
              </span>
            </div>
          }
        </div>
      </>
    );
  };

  render() {
    const {currentTab} = this.state;
    return (
      <div className="tab_container" style={{padding: 'unset'}}>
        <div className="ttp-tab-container">
          <TTPTab
            tabs={[
              {id: 'Configuration', label: _('Configuration')},
              {id: 'Automation', label: _('Automation')},
            ]}
            activeTab={currentTab}
            selectTab={this.handleTabChange}
          />
        </div>
        {currentTab === 'Configuration' && this.renderConfigurationTab()}
        {currentTab === 'Automation' && this.renderAutomationTab()}
      </div>
    );
  }
}



