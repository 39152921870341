import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';

import {saveApprovalResponse} from 'thunks';
import {onSuccess, onError} from 'utils';
import _ from 'i18n';


class ApproveCampaign extends Component {

  saveApprove = () => {
    const {id} = this.props;
    this.props.dispatch(saveApprovalResponse({
      decision: "accept",
      id
    }, this.props.handleSetCurrentPage))
      .then(res => onSuccess(res, {
        body: _('Your view is taken into consideration'),
        title: _('Thank you for approving this campaign')
      }), err => onError(err));
  }

  render() {
    const {updating, history, emailAutomationCampaignConfigCompletion} = this.props;

    return <div className="approve-block align-center">
      <h4
        className="column text-center">{!emailAutomationCampaignConfigCompletion ? _('You are about to approve the campaign') : _('You are about to approve the completion of the campaign')}
        <span
          className="version-info">({_('version')} #{this.props.version})</span>
      </h4>
      <h4
        className="column text-center">{_('Thank you for your collaboration')}</h4>
      <div className="row small-8 align-center">
        <div className="float-right buttons-block">
          <button className="btn secondary"
                  onClick={() => this.props.isUaNewsletter ? this.props.handleSetCurrentPage("LIST_PAGE") : history.push("/")}
          >{_('cancel')}</button>
          <button className="btn primary"
                  onClick={!updating && this.saveApprove}>{updating ? _('Processing...') : _('approve')}</button>
        </div>
      </div>
    </div>;
  }
}


const mapStateToProps = store => ({
  updating: store.approval.list.updating,
  isUaNewsletter: store.currentCampaign.uaNewsletter.isUaNewsletter,

})
export default compose(
  connect(mapStateToProps),
  withRouter
)
(ApproveCampaign);
